$roundedCardBorderRadius: 1.5rem;

.RoundedCard {
    background: #fff;
    border-radius: $roundedCardBorderRadius;
    box-shadow: 
        0px 5px 6px -3px rgba(0,0,0,0.2),
        0px 9px 12px 1px rgba(0,0,0,0.14),
        0px 3px 16px 2px rgba(0,0,0,0.12)
    ;
    display: flex;
    flex-flow: column;
}

.RoundedCardImageContainer {
    display: block;
    position: relative;
    height: 280px;
}

.RoundedCardImageContainer > img {
    border-radius: $roundedCardBorderRadius;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
}

.RoundedCardContentContainer {
    padding: 1rem;
}

.RoundedCardOverheadTitle {
    color: rgba(0, 0, 0, .55);
    font-size: .75rem;
}

.RoundedCardTitle {
    color: #000;
    font-size: 1.25rem;
    font-weight: 700;
}

.RoundedCardActions {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding: 1rem;
}

.RoundedCardButton {
    border-radius: 4rem;
}
