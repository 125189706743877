@import '../../theme/partials/constants/colors.scss';

.ContentInfoButton {
    text-transform: uppercase; 
    margin-top: 10px;
}

.ContentInfoDrawer {
    :global(.ant-drawer-content-wrapper) {
        :global(.ant-drawer-content) {
            background-color: $LayoutComponentBackgroundColor;
        }
    }

    .ContentInfoCloseDrawerIcon {
        display: block;
        font-style: normal;
        text-align: center;
        text-transform: none;
        text-rendering: auto;
        width: 56px;
        height: 56px;
        line-height: 56px;
        font-size: 16px;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
        i {
            color: $text-color;
        }
    }
}