@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';

.avatarWrapper {
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  background-color: $primary;
  .initialWrapper {
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
  }
}

@media #{$sm-low} {
  .avatarWrapper {
    width: 24px;
    height: 24px;
    .initialWrapper {
      font-size: 12px;
    }
  }
}

@media #{$md-only}{
  .avatarWrapper {
    width: 32px;
    height: 32px;
  }
}

@media #{$lg-only} {
  .avatarWrapper {
    width: 40px;
    height: 40px;
  }
}