@import '../../theme/partials/constants/colors.scss';
@import '../../theme/mixins/modal-full.scss';
.CloudinaryVideoModalPreview {
    @include full-screen-modal();
    :global(.ant-modal-content) {
        :global(.ant-modal-header) {
            height: 15%;
        }
        :global(.ant-modal-body) {
            height: 85%;
            padding: 24px 20px;
        }
    }
    .CloudinaryVideoModalContainer {
        height: 100%;

        .CloudinaryVideoModalColumns {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .CloudinaryVideoModalColumns + .CloudinaryVideoModalColumns {
            height: 100%;
        }

        .CloudinaryVideoModalInputContainer {
            p {
                font-weight: bold;
                font-size: 16px;
                padding-bottom: 5px;
            }
            .CloudinaryVideoErrorLabel {
                font-weight: normal;
                font-size: 14px;
                padding-top: 7px;
                color: $tomato;
            }
        }

        .CloudinaryVideoInputError {
            border-color: $tomato;
        }

        .CloudinaryVideoModalInputContainer + .CloudinaryVideoModalInputContainer {
            padding-top: 20px;
        }
    }
}
