@import '../../theme/partials/constants/colors';

.LabelWithHintWrapper {
  width: 100%;
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  .LabelWithHintLabel {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .LabelWithHintIcon {
    font-size: 18px;
    color: $gunmetal;
    :hover {
      color: $primary;
    }
  }
}