@import '../../theme/partials/constants/colors.scss';
@import '../../theme/partials/constants/variables.scss';
@import '../../theme/partials/constants/breakpoints.scss';

.ContainerWithAsideContainer {
    height: 100%;
    overflow-y: auto;
    .ContainerWithAsideScrollableContent {
        height: 100%;
        overflow-y: auto;
        padding: 20px;

        .ContainerWithAsideContentRowWrapper {
            height: 100%;

            .ContainerWithAsideFooterWrapper {
                padding: 15px 0;
            }
        }
    }

    .ContainerWithAsideDrawerButton {
        display: none;
        @media #{$md-low} {
            display: block;
            height: 56px;
            position: absolute;
            right: 0px;
            top: 20%;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            z-index: 10;
        }
    }

    .AsideContainer {
        height: 100%;
    }
}

.ContainerWithAsideDrawer {
    :global(.ant-drawer-body) {
        height: 100%;
        padding: 0;
    }
    :global(.ant-drawer-content-wrapper) {
        width: 70% !important;
    }
}

.ContainerWithAsideContentDisabled {
    pointer-events: none; 
    user-select: none;
}
