@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/breakpoints';
.MediaToUploadContainer {
    box-shadow: var(--cf-card-box-shadow);
    display: flex;
    flex-direction: column;
    border-radius: var(--cf-card-border-radius);
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 40%;
    background-color: $clean-white;
    position: relative;
    @media #{$lg-low} {
        width: 60%;
    }
    @media #{$sm-low} {
        width: 70%;
    }
    @media #{$xs-only} {
        width: 90%;
    }
    cursor: auto;
    flex-shrink: 0;
    & + & {
        margin-left: 20px;
    }
    .PreviewSection {
        flex-grow: 3;
        max-height: 300px;
        img, video, object {
            height:100%;
            width: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            object-fit: cover;
        }
    }
    .InformationSection {
        flex-grow: 1;
        padding: 5%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
            width: 100%;
        }
        div + div{
            margin-top: 10px;
        }

        .PublicIdNotUnique {
            color: $tomato;
        }

        .InvalidPublicId {
            input, :global(.ant-input-group-addon) {
                border-color: $tomato;
            }
        }
    }

    .RemoveMediaButton {
        position: absolute;
        right: 20px;
        top: 20px;
        color: $clean-white;
        font-size: 20px;
        background: $tomato;
        border-radius: 100%;
        padding: 5px;
        cursor: pointer;
    }

    .ActionSection {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 20px;
        top: 20px;
        button {
            font-size: 20px;
            box-shadow: var(--cf-card-box-shadow);
        }
        button + button {
            margin-top: 10px;
        }
    }
}
