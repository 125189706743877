@import '../../../theme/partials/constants/colors';
@import '../../../theme/partials/constants/variables';

.OneFragmentOfPopoverOverride {
  &:global(.ant-popover) {
    max-width: 75%;
  }
}

.OneFragmentOfErrorLabels {
  color: $tomato;
}

.OneFragmentOfErrorBorder {
  border: 1px solid $tomato;
}

.FragmentOfFormItem {
  margin-bottom: 0;
}

.OneFragmentOfContainer {  

  .OneFragmentOfCreateSelectionButton {
    display: flex;
    align-items: center;
  }
}

.OneFragmentOfFragmentTypeButtonContainer {
  max-width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;

  &:first-child, &:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  .OneFragmentOfTypeButton {
    height: 30px;

    .OneFragmentOfTypeName {
      font-size: $fontSizeDefault;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
