@import '../../theme/partials/constants/breakpoints.scss';

.TableWrapperContainer {
    height: 100%;
    padding: 20px;
    position: relative;
    
    .TableWrapperHeader {
        @media #{$xs-up} {
            height: 20%
        }

        @media #{$md-only} {
            height: 10%;
        }

        @media #{$lg-up} {
            height: 10%;
        }
    }

    .TableWrapperFooter {
        text-align: center;
        height: 10%;
    }

    .TableWrapperContent {
        position: static;

        @media #{$xs-up} {
            height: 70%;
        }

        @media #{$md-up} {
            height: 80%;
        }

        @media #{$lg-up} {
            height: 80%;
        }
    }
  }