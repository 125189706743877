@import "../../theme/partials/constants/colors.scss";

.ChannelCheckboxContainer {
    margin-bottom: 5px;

    :global(.ant-checkbox) {
        margin-top: 4px;
        float: left;
    }

    span:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1;
    }

    .ChannelCheckboxChannelColor {
        margin-top: 2px;
        height: 4px;
        width: 100%;
    }

    .ChannelArchived {
        padding: 10px;
        border: 1px solid $warning;
        border-radius: var(--cf-card-border-radius);
        i {
            color: $warning;
        }
    }
}
