@import '../../theme/partials/constants/breakpoints';

.MediaFinderContainer {
    height: 100%;
    padding: 0 30px;
    position: relative;

    .MediaFinderSelectContainer {
        height: 10%;
        width: 100%;

        @media #{$md-low} {
            height: 15%;
        }

        .MediaFinderFolderSelector {
            display: flex;
            align-items: center;
            .MediaFinderFolderSelectorButton {
                font-size: 20px;
                height: auto;
                margin-right: 15px;
            }
        }

        .MediaFinderTagsSelect {
            padding-left: 10px;
            display: flex;
            align-items: center;

            @media #{$md-low} {
                padding-left: 0;
            }

            .MediaFinderSelectTags {
                width: 100%;
                font-size: 16px;

                :global(.ant-select-selection--multiple) {
                    height: 40px;

                    :global(.ant-select-selection__rendered) {
                        height: 100%;

                        ul {
                            height: 100%;
                            li {
                                height: 32px;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }


    .MediaFinderBodyWithForm {
        height: 80%;
    }

    .MediaFinderBodyWithoutForm {
        height: 90%;

        @media #{$md-low} {
            height: 85%;
        }
    }

    .MediaFinderFooter {
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        .MediaFinderConfirm {
            width: 200px;
            text-transform: uppercase;
        }
    }
}