@import '../../theme/partials/constants/colors.scss';

.FolderNavigationContainer {
    font-size: 20px;
    color: $text-color;

    > span {
        cursor: pointer;
        > span {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    > span:last-child {
        font-weight: bold;
        cursor: auto;
        > span {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .FolderNavigationCTAIcon {
        font-size: 20px;
        color: $primary;
    }
}