@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';



.FormContainer {
    display: flex;
    min-height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media #{$md-low} {
        margin: 0 auto;
    }

    
    // .Form {
        @media #{$md-low} {
            margin: 0 auto;
        }
        :global(.ant-form-item) {
            margin-bottom: 0;
        }

        .FormTitle {
            text-align: center;
            margin-top: 1rem;
        }

        .FormActionsContainer {
            text-align: center;
            margin-top: 10px;
            button {
                text-transform: uppercase;
            }
        }

        .CardElementInput {
            font-size: 14px;
            height: 32px;
            input {
                height: 32px;
                font-size: 14px;
            }
            > div {
                height: 100%;
            }
            iframe {
                height: 100% !important;
                div, input, span, form {
                    height: 100% !important;
                }

                :global(.ElementsApp) {
                    height: 100% !important;
                }
            }
        // }
    }

    .ActionContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .ActionIcon {
            font-size: 90px;
        }

        .SuccessIcon {
            color: $primary;
        }

        .WarningIcon {
            color: $warning;
        }

        .Footer {
            margin-top: 1rem;
        }
    }
}

.ReviewModal {
    width: 40%!important;

    @media #{$xl-low} {
        width: 60%!important;
    }

    @media #{$lg-low} {
        width: 75%!important;
    }

    @media #{$md-low} {
        width: 80%!important;
    }

    @media #{$sm-low} {
        width: 100%!important;
    }

    .ReviewModalHeading {
        margin-top: 0;
    }

    .ReviewModalFooter {
        width: 100%;
        text-align: center;
        button {
            text-transform: uppercase;
        }
    }

    :global(.ant-modal-content) {
        border-radius: var(--cf-card-border-radius);
    }

    :global(.ant-modal-header) {
        border-top-left-radius: var(--cf-card-border-radius);
        border-top-right-radius: var(--cf-card-border-radius);
    }

    :global(.ant-modal-footer) {
        border-bottom-left-radius: var(--cf-card-border-radius);
        border-bottom-right-radius: var(--cf-card-border-radius);
    }
    .TotalEntryDefault {
        font-size: 20px;
    }
    
    .TotalEntryValue {
        font-weight: bold;
    }
}

