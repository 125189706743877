.searchFilterContainer {
  display:flex;
  position:relative;
  width:100%;
  i {
    color: black;
  }
  .searchButton {
    font-size: 10px;
    border: none;
    background: none;
    font-style: italic;
    text-decoration: underline;
    position: absolute;
    right: 15px;
    bottom: -15px;
  }
}
