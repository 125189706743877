.FooterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & button:not(:last-child) {
    margin-right: 10px;
  }
}
