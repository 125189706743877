@import "../../theme/partials/constants/colors.scss";
@import "../../theme/partials/constants/breakpoints.scss";


.VerifyEmailCard {
    

    @media #{$xs-only} {
        height: 70%;
        width: 90%;
    }

    @media #{$sm-only} {
        height: 60%;
        width: 80%;
    }

    @media #{$md-only} {
        height: 60%;
        width: 60%;
    }

    @media #{$lg-up} {
        height: 50%;
        width: 40%;
    }

    @media #{$xxl-up} {
        height: 50%;
        width: 30%;
    }

    .LabelContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20%;
        font-size: 16px;

        p {
            position: absolute;
            transform: scale(0);
        }

        .LoaderDone {
            transform: scale(1);
            transition: transform .5s ease-in-out;
        }
    }
    
    .StatusContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 100px;
        flex-grow: 2;
    }
    
    .LoaderOverrides {
        font-size: 80px;
    }
    
    .SuccessIcon {
        color: $primary;
    }
    
    .ErrorIcon {
        color: $tomato;
    }

    .IconHidden {
        position: absolute;
        transform: scale(0);
    }
    
    .IconVisible {
        transition: transform .5s ease-in-out;
        transform: scale(1);
    }

    [class^="RoundedCard_RoundedCardContentContainer"] {
        flex: auto;
        display: flex;
        flex-direction: column;
    }
}