@import '../../theme/partials/constants/colors';

.PublishingChannelListItemContainer {
  display: flex;
  align-items: center;
  border-left: 5px solid;
  padding-left: 1rem;

  .PublishingChannelListItemDataContainer {
    padding-right: 10px;
  }

  .PublishingChannelListItemNameContainer {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .PublishingChannelListItemMnemonicIdContainer {
    color: rgba($color: #000000, $alpha: .55);
    font-style: italic;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .PublishingChannelListItemActionsContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
