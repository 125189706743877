@import '../../theme/partials/constants/colors';

.MediaGalleryListItemActionsPopover {
  :global(.ant-popover-inner-content) {
    padding: 0;
  }

  .MediaGalleryListItemActionsPopoverLinkContainer {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
  }

  .MediaGalleryListItemActionsButton {
    margin: 5px 0;
    width: 100%;
  }
}

.MediaGalleryListItemActionsContainer {
  color: $primary;
  &:hover {
    color: $primary;
  }
}
