@import './constants/breakpoints';
@import './constants/variables';

.ant-layout-sider {
  .ant-menu {
    .ant-menu-submenu-title {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
    .ant-menu-item, 
    .ant-menu-submenu {
      opacity: 0.7;
      height: $sidebar-width-height;
      padding: 12px 20px !important;

      i {
        vertical-align: middle;
        font-size: 24px;
      }

      &.ant-menu-item-selected,
      &.ant-menu-submenu-selected {
        background-color: $gunmetal;
        color: $primary;
        opacity: 1;
        font-weight: bold;
        box-shadow: 5px 0 0 -2px $primary inset;

        > span, > i {
          color: $primary;
        }

        .ant-menu-submenu-title {
          color: inherit;
        }

        .ant-menu-submenu-arrow {
          &::after, &::before {
            background: $primary;
          }
        }
      }
    }
  }
  .ant-menu-inline-collapsed {
    width: $sidebar-width-height;

    .ant-menu-submenu {
      padding: 12px 20px !important;
      .ant-menu-submenu-title {
        height: $sidebar-width-height;
        width: $sidebar-width-height;
        padding: 0!important;

        i {
          vertical-align: middle;
          font-size: 24px;
        }
      }
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-sub {
    border-radius: 0;
    .ant-menu-item {
      i {
        vertical-align: middle;
        font-size: 16px;
      }
      margin-top: 0;
      margin-bottom: 0;
      
    }

    .ant-menu-item-selected {
      background-color: $gunmetal;
      color: $primary;
      opacity: 1;
      font-weight: bold;
      box-shadow: 5px 0 0 -2px $primary inset;

      > span, > i {
        color: $primary;
      }
    }
  }
}
