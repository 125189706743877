.ant-popover {
  .ant-popover-inner {
    border-radius: 5px;
  }

  .ant-popover-buttons {
    button {
      border-radius: 3px;
    }
  }
}