.MediaGalleryListItemOverrideContainer {
  width: 100%;
  cursor: pointer;
}

.MediaGalleryListItemContainer {
  height: 45px;
  width: 100%;
  border-left: 5px solid;
  padding-left: 1rem;

  .MediaGalleryListItemDataContainer {
    padding-right: 10px;
  }

  .MediaGalleryListItemNameContainer {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .MediaGalleryListItemDescriptionContainer {
    color: rgba($color: #000000, $alpha: .55);
    font-style: italic;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .MediaGalleryListItemActionsContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
