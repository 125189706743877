$primary_text_color: #000;
$primary: #00AEEF;
$primary_transparent_50: unquote('#00AEEF80');
$lemon: #fff74f;
$dark: #263238;
$gunmetal: #4f5b62;
$dark-navy-blue: #000912;
$ocre: #c79400;
$emerald: #00a854;
$pale-grey: #eceff1;
$white-two: #d9d9d9;
$tomato: #f04134;
$warning: #faad14;
$light-moss-green: #87d068;
$clean-white: #ffffff;
$lighty-white: #f5f5f5;
$darky-white: #f7f7f7;
$light-gunmetal: #b6bec2;
$LayoutComponentBackgroundColor: #f6f8fa;
$black: #000;
$list-item-separator: #e8e8e8;
$text-color: $black;
$popover-color: $black;
$error: #f5222d;
$red-warning: #ff4d4f;

$electric-blue: #0058ff;
$fade6-electric-blue: fade-out($electric-blue, 0.94);
