@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/breakpoints';

.LinkedContentPreviewWrapper {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  .LinkedContentPreviewLinkToWrapper {
    font-weight: bold;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media #{$sm-low} {
      max-width: 165px;
    }

  }

  .LinkedContentPreviewButtonsWrapper {
    position: absolute;
    top: 0;
    right: 0;

    .LinkedContentPreviewButton {
      width: 50px!important;
      margin-left: 10px;
    }
  }

  .LinkedContentPreviewShowMoreButton {
    margin-left: 10px;
  }
}

.LinkedContentPreviewModalWrapper {
  :global(.ant-modal) {
    height: 90%;
    width: 70%!important;

    :global(.ant-modal-content) {
      height: 100%;
      width: 100%;
      border-radius: 5px;

      :global(.ant-modal-body) {
        height: 100%;
        width: 100%;
        padding: 35px;
      }
    }
  }

  .LinkedContentPreviewModalBodyWrapper {
    height: 100%;
    width: 100%;

    .LinkedContentPreviewModalContentsWrapper {
      height: 90%;
      width: 100%;
      overflow-y: auto;
      text-align: center;
      padding: 10px;
    }

    .LinkedContentPreviewModalPaginationWrapper {
      height: 10%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

}
