.FilterContentsByTagsListWrapper {
  height: 100%;

  :global(.ant-list-header) {
    height: 13%;
  }

  :global(.ant-spin-nested-loading) {
    height: 87%;
    overflow-y: auto;
  }

  .FilterContentsByTagsCheckboxWrapper {
    width: 100%;
  }
}
