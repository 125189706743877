$alert-height: 48px;

.ant-alert {
    &-icon {
        top: 16px;
        left: 10px;
    }
    &-close-icon {
        top: 20px;
        font-weight: bold;
        .anticon-cross {
        color: #404040;
        }
    }
}