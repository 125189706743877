h1 {
	font-size: 36px;
	line-height: 0.89;
}
h2 {
	font-size: 24px;
	line-height: 1.33;
}
h3 {
	font-size: 20px;
	line-height: 1.4;
}
h4 {
	font-size: 16px;
	line-height: 1.5;
}
h5 {
	font-size: 14px;
	line-height: 1.71;
}
h6 {
	font-size: 12px;
	line-height: 1.33;
}

.bold {
	font-weight: bold;
}

.label {
	color: $dark;
	margin-top: 47px;
	margin-bottom: 20px;
	font-weight: bold;
	> .label-description {
		padding-left: 4px;
		font-size: 16px;
		font-weight: normal;
	}
}

.higher-label {
	color: $dark;
}

.section-label {
	font-weight: bold;
}
