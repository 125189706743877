.PartialsContainer {
    background: #fff;
    padding: 1rem;
    position: relative;
}

.SubscriptionDetailPlan {
    position: relative;
    height: 100%;
}

.SubscriptionDetailPlanContentRiseAbove {
    background-color: transparent;
    height: 100%;
    opacity: 0;
    transform: translate3d(0, 10vh, 0);
    transition:
        background-color 400ms ease-in-out,
        opacity 400ms ease-in-out,
        transform 200ms ease-in-out;
    ;
    top: 0;
    width: 100%;
}

.SubscriptionDetailPlanContentRiseAboveButton {
    & + & {
        margin-left: 1rem;
    }
}

.SubscriptionDetailPlanContentRiseAboveContent {
    padding: 3rem 1.5rem;
    width: calc(61.8% - 3rem);

    h1 {
        font-size: 3.052rem;
        margin-bottom: 3rem;
    }

    p {
        font-size: 1rem;
        margin-bottom: .5rem;
    }

    section {
        margin-top: 3rem;
    }
}

.SubscriptionDetailPlanContentRiseAbovePlan {
    background-color: var(--cf-scene-background);
    border-radius: 1.5rem;
    padding: 1.5rem;
    width: calc(38.2% - 3rem);
}

.SubscriptionDetailPlanContentRiseAboveVisible {
    background-color: #fff;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    z-index: 1;
}

.SubscriptionDetailPlanContent {
    display: flex;
    flex-flow: row;
    overflow-x: auto;
    padding: 20px;
    justify-content: center;
}

// .SubscriptionDetailPlanContent > * {
//     flex: 1 0 auto;
// }

.SubscriptionTabTitle {
    color: #848d91;
    font-weight: 700;
}

.List :global(.ant-avatar-circle),
.ListItemAvatar {
    background-color: #4f4c52;
}

.List > :global(.ant-list) {
    padding: 0 0 0 2rem;
}

.ListItemMeta {
    align-items: center;
}
