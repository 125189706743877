@import '../../theme/partials/constants/colors.scss';
@import '../../theme/partials/constants/breakpoints.scss';

:global(.has-error) {
    .CloudinaryPreviewContainer {
        border-color: $tomato;
        transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

.CloudinaryPreviewWrapper {
    width: 100%;

    .CloudinaryPreviewContainer {
        display: flex;
        justify-content: left;
        align-items: flex-start;

        .CloudinaryPreviewAdd, .CloudinaryPreviewImage {
            border: 1px solid $white-two;
            padding: 10px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .CloudinaryPreviewEdit {
            margin-left: 10px;
            height: 100%;
            flex-direction: column;
            display: flex;
            justify-content: center;

            button + button {
                margin-top: 10px;
            }

        }

        .CloudinaryPreviewImage {

            img {
                width: 250px;
                height: auto;
                border-radius: 5px;
            }
        }

        .CloudinaryPreviewNoImage {
            padding-right: 20px;
            margin-bottom: 24px;
        }

    }
}
