@import "../../theme/partials/constants/breakpoints";

.FilterModalIcon {
  vertical-align: middle;
  padding-right: 5px;
}

.FilterModalModalContainer {
  @media #{$md-up} {
    :global(.ant-modal-content) {
      :global(.ant-modal-body) {
        height: 500px;
        padding: 34px 24px 10px 24px;
      }
    }
  }

  @media #{$sm-low} {
    height: 100vh!important;
    width: 100vw!important;
    max-width: 100vw!important;
    top: 0;
    padding: 0;
    margin: 0;
    :global(.ant-modal-content) {
      height: 100%;

      :global(.ant-modal-body) {
        height: 90%;
        overflow-y: auto;
      }
      :global(.ant-modal-footer) {
        height: 10%;
      }
    }
  }
}
