@import '../../../theme/partials/constants/colors.scss';

:global(.ant-form-item-required) {
    display: flex;
    align-items: center;
}

.StaticContentFieldFormItemLabel {
    display: flex;
    align-items: center;

    :global(.anticon) {
        font-size: 18px;
        margin-left: 5px;
        line-height: 0!important;
    }
}

.StaticContentFieldDivider {
    background-color: $gunmetal;
}
