@import "../../theme/partials/constants/variables.scss";

.BaseLayoutContainer {
  height: 100%;
  width: 100%;
}

.BaseLayoutContent {
  width: 100%;
  height: 100%;
  position: relative;
}
