@import '../../theme/partials/constants/colors.scss';
.CloudinaryImageCard {
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    background-color: $pale-grey;

    img {
        border-radius: 5px;
        width: 100%;
        height: auto;
    }
}