@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/breakpoints';

.MissingPermissionsWrapper {
  width: 100%;
  height: 100%;
  display: flex;

  .MissingPermissionsContentWrapper {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
    box-shadow: rgba(0, 21, 41, 0.35) 3px 3px 6px 2px;
    margin: auto;
    color: #00000080;
    border-radius: $defaultBorderRadius;

    @media #{$sm-low} {
      width: 100%;
      height: 100%;
    }

    .MissingPermissionsAccessDenied {
      font-size: $fontSizeExtraLarge;
      padding-bottom: 15px;
    }

    .MissingPermissionsDivider {
      width: 50%;
      margin: 0 auto;
    }

    .MissingPermissionsInformation {
      padding-top: 15px;
      font-size: $fontSizeDefault;
    }

    .MissingPermissionsContact {
      padding-top: 5px;
      padding-bottom: 15px;
      font-size: $fontSizeDefault;
    }

    .MissingPermissionsIcon {
      font-size: 100px;
      color: $tomato;
    }
  }
}
