@import '../../theme/partials/constants/colors';

.PublishSummaryContainer {
    padding: 10px 0;
    background-color: $clean-white;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;

    .PublishSummaryItem {
        text-align: center;
        padding: 10px 0;
    }
    
    .PublishSummaryItem + .PublishSummaryItem {
        border-top: 1px solid $white-two;
    }

    .PublishSummaryEmptyContainer {
        text-align: center;
    }
}