@import "../../theme/partials/constants/colors";

.HorizontalLoaderContainer {
    width: 70px;
    text-align: center;

    & > div {
        width: 18px;
        height: 18px;
        background-color: $primary;
        border-radius: 100%;
        display: inline-block;
        animation: bounce-dot 1.4s infinite ease-in-out both;
    }

    .firstDot {
        animation-delay: -320ms;
    }

    .secondDot {
        animation-delay: -160ms;
    }

}

@keyframes bounce-dot {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}
