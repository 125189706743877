@import '../../theme/partials/constants/colors';
.NotSynchronizedBadge {
    :global(.ant-badge-dot) {
        background: $warning;
    }
}

.Synchronized {
    :global(.ant-badge-dot) {
        background-color: $primary;
    }
}