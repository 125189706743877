@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/variables';
.ToggleContainer {
    display: flex;
    background-color: $clean-white;
    border-radius: var(--cf-card-border-radius);
    box-shadow: var(--cf-card-box-shadow);
    margin: 20px;

    .ToggleItem {
        padding: 20px;
        p {
            font-size: $fontSizeLarge;
        }
        cursor: pointer;
        transition: background-color 0.2s ease;
        transition: color 0.2s ease;
    }

    .ToggleItemLeft {
        border-top-left-radius: var(--cf-card-border-radius);
        border-bottom-left-radius: var(--cf-card-border-radius);
    }

    .ToggleItemRight {
        border-top-right-radius: var(--cf-card-border-radius);
        border-bottom-right-radius: var(--cf-card-border-radius);
    }

    .SelectedItem {
        background-color: $primary;
        color: $clean-white;
        font-weight: bold;
    }
}