.PopoverTitleWrapper {
  display: flex;
  align-items: center;

  .PopoverTitleIcon {
    font-size: 18px;
    margin-right: 0.5rem;
    color: red;
  }
}

.PopoverContent {
  font-size: 0.85rem;
}
