@import "../../theme/partials/constants/_colors.scss";
@import "../../theme/partials/constants/_variables.scss";

.PublishingRequestsSummaryContainer {
    background-color: $clean-white;
    border-radius: 5px;
    .PublishingRequestsSummaryLoader {
        min-height: 20px;
    }

    .PublishingRequestsSummaryEmpty {
        padding: 20px;
        text-align: center;
    }

    .PublicationRequestsSummaryTitle {
        padding: 19px 18px;
        font-size: $fontSizeDefault;
        background-color: $gunmetal;
        color: $lighty-white;
        text-align: center;
    }

    .PublishingRequestsSummaryRequestWrapper {
        max-height: 220px;
        overflow-y: auto;
        
        .PublishingRequestsSummaryRequest {
            padding: 10px;
            
            .PublishingRequestsSummaryRequestInfo {
                font-size: $fontSizeSmall;
            }
        
            .PublishingRequestsSummaryRequestStatus {
                text-align: center;
                padding: 10px 0;
                text-transform: uppercase;
            }
        
            .failed {
                color: $tomato;
            }
        
            .queued {
                color: $primary;
            }

            .processing {
                color: $primary;
            }
        
            .published {
                color: $light-moss-green;
            }

            .already_published {
                color: $primary;
            }

        }
    
        .PublishingRequestsSummaryRequest + .PublishingRequestsSummaryRequest {
            border-top: 1px solid $white-two;
        }
    
    }

}