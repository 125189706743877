.ContentListCreateContentButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    font-size: 25px;
    height: 50px;
    box-shadow: 2px 6px 5px 0 rgba(0, 0, 0, 0.5)
}

.ContentListContainer {
    height: 100%;
    overflow-y: auto;
    padding: 10px 0;
}
