@import '../../theme/partials/constants/colors.scss';
@import '../../theme/partials/constants/breakpoints.scss';

.LatestActivitiesContainer {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    > div {
        max-height: 100%;
        display: flex;
    }
    @media #{$md-low} {
        > div {
            max-height: 500px;
        }
    }
    .LatestActivitiesListContainer {
        background-color: var(--cf-card-background-color);
        border: 1px solid $pale-grey;
        border-radius: 5px;
        box-shadow: var(--cf-card-box-shadow);
        overflow: auto;
        width: 100%;
    }
}

.ListContainer {
    > div[class*='Section_ContentBody'] {
        display: flex;
    }
}