@import '../../theme/partials/constants/colors.scss';
.Folder {
    display: flex;
    flex-direction: column;
    height: 100%; 
    align-items: center; 
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px $text-color;

    .FolderIcon {
        font-size: 100px;
    }
}