@import '../../../../theme/partials/constants/colors';

.Container {
    display: flex;
    .NotSynchronized {
        color: $warning;
    }
    
    .Synchronized {
        color: $emerald;
    }
    
    .Button {
        text-transform: uppercase;
        margin-left: 10px;
    }
}