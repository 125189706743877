:global(.ant-form-item-label) {
  label {
    color: rgba($color: #000000, $alpha: .55);
    font-weight: 400;
  }
}

.DynamicFieldContainer {
  width: 100%;
  :global(.ant-form-item-label) {
    label {
      display: flex;
      align-items: center;
    }
  }
}