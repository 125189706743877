%wizard-typography-baseline {
    margin-bottom: 1rem;
    line-height: 1.1;
}

@mixin animate($delay: 0) {
    animation: slide-up 500ms ease-in-out $delay forwards;
    opacity: 0;
    transform: translate3d(0, 100%, 0);
}

.WizardContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
}

.Wizard {
    max-width: 30vw;
}

.WizardEula {
    display: block;
    font-size: .75rem;
    line-height: 1.5;
}

.WizardEula a {
    font-weight: 700;
    margin: 0 .25rem;
}

.WizardHint {
    color: rgba(#000, .54);
    font-size: .75rem;
}

.WizardTitle {
    @extend %wizard-typography-baseline;
    @include animate(500ms);
    color: #000;
    font-size: 3.052rem;
    font-weight: 700;
}

.WizardSubTitle {
    @extend %wizard-typography-baseline;
    @include animate(600ms);
    color: rgba(#000, .89);
    font-size: 1.953rem;
}

.WizardText {
    @extend %wizard-typography-baseline;
    @include animate(700ms);
    color: rgba(#000, .63);
    font-size: 1rem;
    font-style: italic;
    line-height: 1.61;
}

.WizardForm {
    @extend %wizard-typography-baseline;
    @include animate(700ms);
    font-size: 1rem;
    margin: 0 auto;
    max-width: 20rem;
}

.WizardForm :global(.ant-checkbox-wrapper) {
    align-items: center;
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.WizardForm :global(.ant-form-item-control) {
    line-height: 1.5;
}

.WizardForm :global(.ant-form-item) {
    text-align: left;
}

.WizardButton {
    @extend %wizard-typography-baseline;
    @include animate(1100ms);
    font-size: .75rem;
}

@keyframes slide-up {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}