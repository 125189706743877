@import '../../../theme/partials/constants/colors.scss';

.FieldNameEditing {
  :global(.ant-form-item-control-wrapper) {
    :global(.ant-form-item-control) {
      :global(.ant-form-item-children) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          margin-left: 1em;
        }
      }
    }
  }
}
.FieldName {
  margin-bottom: 0;
}

.Tabs {
  height: 100%;
  display: flex;
  flex-direction: column;

  :global(.ant-tabs-content) {
    min-height: 0px;
    flex-grow: 1;
    :global(.ant-tabs-tabpane) {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 5px 0;
      position: relative;

      :global(#DetailsTabContent), :global(#ConstraintsTabContent), :global(#UiTabContent) {
        transition: flex 5s linear;
        flex: 1;
        overflow-y: auto;
      }
    }
  }
}

.ScrollToArrow {
  z-index: 50;
  position: absolute;
  font-size: 20px;
  right: 0;
}

.ScrollToTopArrow {
  top: 0;
  i {
    transform: rotate(90deg);
    position: relative;
    animation: bounce 0.5s 5 linear;
  }
}

.ScrollToBottomArrow {
  bottom: 0;
  i {
    transform: rotate(-90deg);
    position: relative;
    animation: bounce 0.5s 5 linear;
  }
}


@keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
