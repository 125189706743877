@import '../../theme/partials/constants/variables';

.HelpIcon {
    font-size: $fontSizeNavIcon;
    cursor: pointer;
    padding-right: 20px;
}

.HelpLink + .HelpLink {
    margin-top: 10px;
}

.HelpHr {
    border-bottom: 0 none;
    border-top: 1px solid rgba(0, 0, 0, .08);
    margin: 1rem 0 calc(1rem - 1px);
}

.HelpTours {
    margin-top: 1rem;
}

.HelpTourButton {
    font-size: .875rem;
    font-weight: 400;

    &:hover {
        border-color: rgba(0, 0, 0, .54);
        color: rgba(0, 0, 0, .89);
    }
}
