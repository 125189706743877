@import '../../theme/partials/constants/breakpoints.scss';

@mixin full-screen-modal() {

    :global(.ant-modal) {
        width: 100%!important;
        height: 100vh;
        padding: 0;
        top: 0;
        
        :global(.ant-modal-content) {
            height: 100%;
            width: 100vw;
            
            :global(.ant-modal-header), :global(.ant-modal-body), :global(.ant-modal-footer) {
                border: 0;
            }
        }
    }
    
    @media #{$sm-low} {
        :global(.ant-modal) {
            margin: 0;
        }
    }
} 