@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/breakpoints';

.HeaderContainer {
    background-color: $dark;
    padding: 0;
    width: 100%;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 12;

    .HeaderLogoContainer {
        display: flex;
        align-items: center;
        height: 100%;

        span {
            font-family: "Museo Sans 700";
            font-size: 24px;
            margin:  0 8px;
            color: $gunmetal;

             @media #{$sm-low} {
                display: none;
            }
        }
    }

    .NavbarActionsContainer {
        display: flex;
        height: 100%;
        margin-left: auto;
    }
}
