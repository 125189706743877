@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/breakpoints';

.ContentCardIconStyleOverwrite {
  vertical-align: -0.125em;
  line-height: 0;
  padding-right: 5px;
  font-size: 20px;
}

.ContentCardTagsIconStyleOverwrite {
  vertical-align: -0.125em;
  line-height: 0;
  padding-right: 5px;
  font-size: 12px;
}

.ContentCardWrapperCol {
  display: flex;
  padding-bottom: 10px;

  .ContentCardWrapperCard {
    border-radius: 5px;
    box-shadow: 2px 0 6px rgba(0,21,41,.35);;
    width: 100%;

    .ContentCardContainer {
      display: flex;
      flex-direction: row;

      .ContentCardSelectableContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 10px;
      }

      .ContentCardRepositoryColorContainer {
        width: 5px;
      }

      .ContentCardBodyWrapper {
        width: 100%;
        padding-left: 1rem;

        .ContentCardLeftBodyContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .ContentCardContentNameWrapper {
            font-weight: bold;
          }

          .ContentCardContentPublicIdWrapper {
            display: flex;
            align-items: center;
            font-style: italic;
            padding: 5px 0 0;
          }

          .ContentCardContentLocaleWrapper {
            display: flex;
            align-items: center;
            padding: 5px 0 0;
          }

          .ContentCardContentTagsWrapper {
            min-height: 55px;
            max-height: 55px;
            margin-top: 5px;
            text-align: left;
            overflow: hidden;
          }
        }

        .ContentCardRightBodyContainer {
          height: 100%;
          @media #{$sm-low} {
              height: auto;
          }
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 10px;

          .ContentCardDefinitionMnenonicWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 5px;
          }

          .ContentCardRepositoryNameWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .ContentCardPublicationDateWrapper {
            display: flex;
            justify-content: flex-start;

            &:nth-child(1) :global(.anticon-calendar) {
              color: $light-moss-green;
            }

            &:nth-child(2) :global(.anticon-calendar) {
              color: $tomato;
            }

            .ContentCardPublicationDateContainer {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .ContentCardActionsContainer {
        padding-left: 10px;
        margin: auto 0;
      }
    }
  }
}
