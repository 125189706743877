.AddSection {
    display: flex;
    flex-flow: row;
}

.AddSectionButton {
    height: 2rem;
    width: 100%;
}

.AddSectionSelect {
    width: 3rem;
}

.LanguageField {
    width: 100%;
    margin-bottom: 7px;
    &:last-child {
        margin-bottom: 0;
    }
}

.LanguageFieldRemoveButton {
    flex: 0 0 auto;
    height: 2rem;
    width: 3rem;
}

.LanguageFieldInput {
    flex: 0 0 auto;
}

.LanguageFieldInputLocale {
    border: 0 none;
    cursor: default;
    flex: 0 0 auto;
    text-align: center;
    width: 3rem;
}

.LanguageFieldInputReduced {
    width: calc(100% - 6rem);
}

