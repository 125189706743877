@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/breakpoints';
@import '../../theme/mixins/select_tag';

.CloudinaryImageUploadDropZoneContainer {
    width: 100%;
    height: 100%;
    padding: 30px;
    position:relative;

    .CloudinaryImageUploadPreviousStep {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%);
        min-width: 100px;
        height: 40px;
        i {
            cursor: pointer;
            font-size: 20px;
        }
    }

    .CloudinaryImageUploadDropZoneInfo {
        border: 2px dashed $primary;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: $pale-grey;
        cursor: pointer;
    }

    .CloudinaryImageUploadDropZoneInfoOnDrag {
        border-width: 3px;
        border-color: $lemon;
        background-color: $light-gunmetal;

        
        .CloudinaryImageUploadDropZoneUploadStep {
            i {
                color: $lemon;
            }
        }

    }

    .CloudinaryImageUploadDropZoneUploadStep {
        text-align: center;
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        align-items: center;
        flex-direction: column;

        .CloudinaryImageUploadPreview {
            height: 300px;
            margin: 20px 0;
            img, video, object {
                height: 100%;
                max-width: 100%;
                border-radius: 5px;
                object-fit: contain;
            }
        }

        .CloudinaryImageUploadNameLabel {
            font-size: $fontSizeDefault;
        }

        .CloudinaryImageUploadButton {
            padding: 0 10px;
            margin: 20px 0;
            text-transform: uppercase;
        }

        .CloudinaryImageUploadPublicIdNotUniqueLabel {
            color: red;
        }

        .CloudinaryImageUploadInteractionContainer {

            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0 auto;

            .CloudinaryImageUploadInputContainer {
                display: flex;
                align-items: center;
                width: 100%;
                .CloudinaryImageUploadIcon {
                    font-size: 20px;
                    color: $tomato;
                    padding-left: 5px;
                    cursor: pointer;
                }
            }
    
            .CloudinaryImageUploadSelectContainer {
                padding-top: 10px;
                width: 100%;
                display: flex;
                justify-content: center;
                .CloudinaryImageUploadSelect {
                    @include select-tag-backgroundColor();
                    width: 100%;
                    max-width: 600px;

                    @media #{$md-only} {
                        max-width: 500px;
                    }

                    @media #{$xs-only}, #{$sm-only} {
                        max-width: 240px;
                    }
                }
            }
        }


    }

    .CloudinaryImageUploadMediaList {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 100%;
        width: 100%;
        overflow-x: auto;
        padding: 0 20px;
        justify-content: center;
    }

    .MoreThanTwoMedia {
        justify-content: start;
    }

    .CloudinaryImageUploadEmptyContainer {

        text-align: center;

        i {
            font-size: 40px;
            color: $primary;
        }

        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .CloudinaryImageUploadInfoLabel {
            font-size: 16px;
            padding-top: 20px;
        } 
    }

    .CloudinaryImageUploadDropZoneInfoOnDrag {
        border-width: 3px;
        border-color: $lemon;
        background-color: $light-gunmetal;

        .CloudinaryImageUploadEmptyContainer {
            i {
                color: $lemon;
            }
        }

    }

    @media #{$sm-low} {
        .CloudinaryImageUploadDropZoneUploadStep {
            .CloudinaryImageUploadPreview {
                height: 150px;
            }
        }
    }

    @media #{$md-up} and (max-height: 745px) {
        .CloudinaryImageUploadDropZoneUploadStep {
            height: 100%;
            padding-top: 15px;
            .CloudinaryImageUploadPreview {
                height: 50%;
            }
        }
    }
    
}

.CloudinaryImageUploadErrorNotification {
    color: $tomato;
    text-transform: uppercase;
    :global(.ant-notification-notice-message) {
        color: $tomato;
    }
}

@media #{$sm-low} {
    .CloudinaryImageUploadErrorNotification {
        margin-left: 15px;
    }
}
