@import '../../theme/partials/constants/variables';

.FilterByRepositoriesListWrapper {
  width: 100%;
  height: 100%;

  :global(.ant-spin-nested-loading) {
    height: 100%;
    overflow-y: auto;
  }

  > a {
    display: block;
    padding-top: 10px;
  }

  .FilterByRepositoriesCheckboxWrapper {
    width: 100%;
    display: flex;
    align-items: center;

    :global(.ant-checkbox) {
      vertical-align: baseline;
      top: 0;
    }

    > span:nth-child(2) {
      width: 100%;
    }

    .FilterByRepositoriesOptionWrapper {
      display: flex;
      align-items: center;

      .FilterByRepositoriesOptionColorWrapper {
        display: flex;

        .FilterByRepositoriesOptionColor {
          height: 15px;
          width: 15px;
        }
      }

      .FilterByRepositoriesOptionNameWrapper {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .FilterByRepositoriesOptionName {
          padding-left: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
