@import '../../theme/partials/constants/variables';

.SectionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  .SectionTitle {
    color: rgba($color: #000000, $alpha: .55);
    font-weight: 700;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    .TitleLabel {
      font-size: $fontSizeLarge;
      line-height: 24px;
    }
  }

  .SectionContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;

    .ContentHeader {
      padding-bottom: 1em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .ContentBody {
      flex: 1;
      overflow: auto;
      padding: 10px 10px;
    }

    .ContentFooter {
      padding-top: 1em;
      display: flex;
      justify-content: flex-end;
    }
  }
}
