@import '../../theme/partials/constants/colors';
.Container {
    background-color: transparent!important;
    :global(.ant-collapse-item) {
        border-bottom: 0px!important;
    }
    :global(.ant-collapse-header) {
        padding: 0!important;
        padding-left: 19px!important;
        color: $primary!important;
        :global(.ant-collapse-arrow) {
            left: 0!important;
            color: $primary!important;
        }
    }
}