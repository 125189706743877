@import '../../theme/partials/constants/colors.scss';
@import '../../theme/partials/constants/variables.scss';
@import '../../theme/partials/constants/breakpoints.scss';

.ActivityPanelLink {
  width: 100%;
}
.ActivityPanelRowContainer {
  width: 100%;
}
.ActivityIcon {
  font-size: 30px;
  color: black;
}
.ActivityImageCol {
  img {
    height: 96px;
    width: 96px;
  }
}
.ActivityStatusCol {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .ActivityStatusWrapper {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    & > * {
      color: $dark;
      font-size: $fontSizeSmall;
    }
    .StatusRow {
      margin-bottom: 1px;
      display: block;
      .StatusLabel {
        display: inline-block;
        padding-right: 5px;
      }
    }
    .DateRow {
      .DateContent {
        margin-bottom: 1px;
      }
    }
  }
}
.ActivityDetailsCol{
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .ActivityDetailsWrapper {
    width: 100%;
    & > * {
      color: #000;
    }
    .DetailsRepository {
      color: rgba($color: #000000, $alpha: .55);
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
      font-size: $fontSizeSmall;
      padding: 5px 0;
    }
    .DetailsTitle {
      max-width: 100%;
      width: 100%;
      font-size: $fontSizeDefault;
      line-height: 18px;
      text-transform: capitalize;
      p {
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .DetailsLastAction {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        line-height: 22px;
        padding-bottom: 10px;
        font-size: $fontSizeSmall;
      }
    }
  }
}

.ActivityPanel .DetailsLastAction {
  color: rgba($color: #000000, $alpha: .55);
  font-style: italic;
}