@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/breakpoints';

.ContentDefinitionListItemDataContainer {
  display: flex;
  align-items: center;

  .ContentDefinitionListItemIcons {
    color: rgba(0, 0, 0, .34);
    vertical-align: middle;
    font-size: 20px;
    padding-right: 10px;
  }

  .ContentDefinitionListItemDefinitionDataContainer {
    display: flex;
    align-items: center;

    .ContentDefinitionListItemInfoContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .ContentDefinitionListItemNameWrapper {
        width: 100%;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .ContentDefinitionListItemMnemonicWrapper {
        color: rgba(0, 0, 0, .55);
        width: 100%;
        font-style: italic;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .ContentDefinitionListItemAuthorContainer {
    display: flex;
    flex-direction: column;

    .ContentDefinitionListItemLastEditInfoWrapper {
      display: flex;

      .ContentDefinitionListItemEditInfoTextContainer {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    
    .ContentDefinitionListItemLastEditInfoWrapper:last-of-type .ContentDefinitionListItemEditInfoTextContainer {
      color: rgba(0, 0, 0, .55);
      font-size: 12px;
    }
  }

  .ContentDefinitionListItemActionsContainer {
    display: flex;
    justify-content: center;
  }
}
