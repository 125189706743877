.ContentDefinitionHeaderContainer {
    margin-top: 5px;

    .newContentDefinitionXSButton {
        padding-bottom: 10px;
    }
}

.CreateButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    font-size: 25px;
    height: 50px;
    box-shadow: 2px 6px 5px 0 rgba(0, 0, 0, 0.5)
}