@import '../../../theme/partials/constants/colors';
@import '../../../theme/partials/constants/breakpoints';

.ExtensionItemAvatar {
    color: rgba(0, 0, 0, .34);
    background: transparent;
    border-radius: 0%;
}

.ExtensionItemDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    @media #{$lg-up} {
        display: flex;
        align-items: center;
        justify-content: center;
    }

  }

  .ExtensionItemIcons {
    color: rgba(0, 0, 0, .34);
    vertical-align: middle;
    font-size: 20px;
    padding-right: 10px;
  }

  .ExtensionItemNameMnemonicContainer {
    display: flex;
    align-items: center;

    .ExtensionItemInfoContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .ExtensionItemNameWrapper {
        width: 100%;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .ExtensionItemMnemonicWrapper {
        color: rgba(0, 0, 0, .55);
        width: 100%;
        font-style: italic;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .ExtensionItemTagContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ExtensionItemTagWrapper {
      display: flex;
      flex-wrap: wrap;
      @media #{$md-low} {
        margin-top: 16px;
      }
      :global(.ant-tag) {
        margin-bottom: 0;
      }
    }
  }

  .ExtensionItemActionsContainer {
    display: flex;
    justify-content: center;
  }
}
