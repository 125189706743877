%FullPageModalPadded {
    padding: var(--cf-card-padding);
}

%FullPageModalDarkerBackground {
    background-color: var(--cf-scene-background);
}

.FullPageModal {
    background-color: var(--cf-card-background-color);
    display: flex;
    flex-flow: column;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100vw;
    z-index: -1;
}

.FullPageModalAnimated {
    opacity: 0;
    transform: scale(.5);
    transition:
        opacity 200ms ease-in-out,
        transform 330ms ease-in-out
    ;
}

.FullPageModalContent {
    @extend %FullPageModalPadded;
    flex: 1 0 auto;
}

.FullPageModalContentButton {
    font-size: 1rem;
    flex: 0 0 auto;
}

.FullPageModalFooter {
    @extend %FullPageModalPadded;
    @extend %FullPageModalDarkerBackground;
    border-top: var(--cf-settings-content-header-border);
    line-height: 1;
}

.FullPageModalFooterEmpty {
    display: none;
}

.FullPageModalTitle {
    @extend %FullPageModalPadded;
    @extend %FullPageModalDarkerBackground;
    align-items: center;
    border-bottom: var(--cf-settings-content-header-border);
    color: rgba(#000, .89);
    display: flex;
    flex-flow: row;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    justify-content: space-between;
}

.FullPageModalVisible {
    visibility: visible;
    z-index: 1000000000;
}

.FullPageModalAnimated.FullPageModalVisible {
    opacity: 1;
    transform: scale(1);
}
