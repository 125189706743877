.TabsContainer {
  min-height: 400px;

  .HistoryTabContainer {
    .ActionDateText {
      font-style: italic;
      color: rgba(0, 0, 0, 0.65);
      margin: 0 5px;
    }
  }

  .ActionButtonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .ExtraContentContainer {
    & > * + * {
      margin-left: 10px;
    }
  }
}
