.MediaGalleryListContainer {
    overflow-y: auto;
    height: 100%;
    margin-left: 0;
    margin-right: 0;

    .MediaGalleryListEmptyIcon {
        font-size: 70px;
    }

    .MediaGalleryListEmptyDescription {
        font-size: 1.5em;
    }

}

.MediaGalleryListPagination {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
