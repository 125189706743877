.RepositoryListItemContainer {
  display: flex;
  align-items: center;
  border-left: 5px solid;
  padding-left: 1rem;

  .RepositoryListItemDataContainer {
    display: flex;
    align-items: center;
    padding-right: 10px;

    .RepositoryListItemIcons {
      color: rgba(0, 0, 0, .34);
      vertical-align: middle;
      font-size: 20px;
      padding-right: 10px;
    }

    .RepositoryListItemNameContainer {
      font-weight: bold;
    }

    .RepositoryListItemMnemonicIdContainer {
      font-style: italic;
    }

    .RepositoryListItemDescriptionContainer {
      color: rgba($color: #000000, $alpha: .55);
      font-style: italic;
      padding-top: 5px;
    }
  }

  .RepositoryListItemActionsContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
