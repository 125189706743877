@import '../../theme/partials/constants/variables';

.NotificationHubTrigger {
    display: flex;
    padding-right: 20px;
    .NotificationIcon {
        font-size: $fontSizeNavIcon;
        cursor: pointer;
    }
}
