@import '../../theme/partials/constants/variables';

.PageNotFoundContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
        font-size: $fontSizeExtraLarge;
    }
}