.NavbarLayoutContainer {
  width: 100%;
  height: 100%;
}

.NavbarLayoutContent {
  background-color: var(--cf-scene-background);
  flex: 1;
  width: 100%;
  overflow-y: auto;
  & > :nth-child(2) {
    flex: 1;
  }
}
