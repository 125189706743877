@import '../../../../theme/partials/constants/variables';
@import '../../../../theme/partials/constants/breakpoints';
.ItemContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;

    .Info {
        grid-area: 1 / 1 / 2/ 2;
        justify-self: stretch;

        .KeyContainer {
            display: flex;

            .Value {
                margin-bottom: 0;
            }

            .KeyActions {
                padding-left: 15px;
                button + button {
                    margin-left: 5px;
                }
            }
        }
    }
    .Access {
        grid-area: 1 / 2 / 2 / 3;
    }
    .Actions {
        grid-area: 1 / 3 / 2 / 4;
    }

    .Info, .Access {
        p {
            font-size: $fontSizeDefault;
        }
    }

    .Date {
        color: rgba(0, 0, 0, .55);
    }

    // @media #{$md-low} {
    //     grid-template-columns: auto repeat(2, 1fr);
    // }

    @media #{$xs-only} {
        grid-template-columns: repeat(2, auto) 1fr;
    }

}