@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';

$text-color: $primary_text_color;

.Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .Heading {
        font-size: inherit;
        text-transform: capitalize;
        color: $text-color;

        i {
            color: darken($color: $text-color, $amount: 5%);
            opacity: 0.8;
        }
    }
}
