.Paragraph {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.45;
    margin-bottom: 1.25em;
}

.H1,
.H2,
.H3,
.H4,
.H5,
.H6 {
    margin: 2.75rem 0 1rem;
    font-weight: 700;
    line-height: 1.15;
}

.H1 {
    margin-top: 0;
    font-size: 3.052rem;
}

.H2 {
    font-size: 2.441rem;
}

.H3 {
    font-size: 1.953rem;
}

.H4 {
    font-size: 1.563rem;
}

.H5 {
    font-size: 1.25rem;
}

.H6 {
    font-size: 1rem;
}

.Label {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1;
    margin: .25rem 0 0;
}

.Quote {
    font-size: .75rem;
    font-weight: 400;
    font-style: italic;
}

.Caption {
    font-size: .625rem;
    font-weight: 400;
}

.NoMargin {
    margin: 0;
}

.Center {
    text-align: center;
}

.Left {
    text-align: left;
}

.Right {
    text-align: right;
}

.Underline {
    text-decoration: underline;
}
