@import "../../theme/partials/constants/colors";
@import "../../theme/partials/constants/variables";
@import "../../theme/animations/animations";

.NotificationHubItem {
    min-height: 80px;
    border-radius: 20px;
    background-color: white;
    box-shadow: var(--cf-card-box-shadow);
    color: $primary_text_color;
    position: relative;
    display: flex;
    flex-direction: column;
    animation-name: fadeInRight;
    animation-duration: 0.2s;
    animation-fill-mode: both;

    @for $i from 2 through 5 {
        &:nth-of-type(#{$i}) {
            animation-delay: #{($i - 1) * 0.2}s;
        }
    }
    
    .IconContainer {
        font-size: $fontSizeNavIcon;
    }

    .LabelContainer {
        flex-grow: 1;
        padding: 0 10px;
    }

    .CTAIconContainer {
        font-size: 12px;
    }

    .NotificationHubItemInfo {
        display: flex;
        align-items: center;
        flex: auto;
        margin: 0px 10px;
    }

    .RemovableAction {
        color: $tomato;
        text-align: center;
        border-top: 1px solid $pale-grey;
        cursor: pointer;
        font-size: 12px;
    }

    .NotificationHubItemLink {
        display: block;
        color: $primary_text_color;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        flex: auto;
        &:focus, &:active, &:hover, &:visited, &:link {
            text-decoration: none;
        }
    }
}

.NotificationHubItem + .NotificationHubItem {
    margin-top: 10px;
}

.NotificationHubItem.ItemFadeOut {
    animation-name: fadeOutRight;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-delay: 0s;
    z-index: 12;
}
