@import '../../theme/partials/constants/colors';

.SpaceSelectorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: $lighty-white;

  .SpaceSelectorListWrapper {
    display: flex;
    flex-direction: column;
    height: 450px;
    padding: 30px;
    min-width: 380px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 3px 13px -5px rgba(0,0,0,.34);

    .SpaceSelectorListTitle {
      margin-bottom: 1rem;
      opacity: .58;
    }

    .SpaceSelectorListSpace {
      flex-grow: 1;
    }
  }
}
