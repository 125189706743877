@import "../../theme/partials/constants/colors";
@import "../../theme/partials/constants/breakpoints";

.Entry {
    .EntryTitle {
        text-transform: capitalize;
        color: $gunmetal;
        font-weight: bold;
    }

    .EntryLabelIcon {
        margin-left: 5px;
    }

     @media #{$sm-low} {
        .EntryValue {
            margin-top: 10px;
        }
     }

    }
.AlignRight {
    text-align: right;    
}

.AlignCenter {
    text-align: center;
}

.AlignLeft {
    text-align: left;
}

.Entry + .Entry {
    margin-top: 10px;
}