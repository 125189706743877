@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';
.StatusTable {
  max-height: 100%;
  cursor: default;

  :global(.ant-table-small > .ant-table-content > .ant-table-body) {
    margin: 0;
  }

  :global(.ant-table-tbody > tr) {
    height: 30px;
  }

  :global(.ant-tag) {
    margin-bottom: 0;
    cursor: pointer;
  }

  .StatusChannelsContainer {
    cursor: pointer;
    :global(.ant-tag) {
      max-width: 85px;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 0;
      vertical-align: top;
    }
  }

  .EnvironmentContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .SameVersion {
      color: $primary;
      font-size: calc($fontSizeNavIcon / 2);
    }

    .DifferentVersion {
      color: $warning;
      font-size: calc($fontSizeNavIcon / 2);
    }
  }
}
.StatusIcon {
  font-size: $fontSizeNavIcon;

  &.Primary {
    color: $primary;
  }

  &.Warning {
    color: $warning;
  }

  &Error {
    color: $error;
  }
}
