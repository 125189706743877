.ant-modal {
    .ant-modal-content, .ant-modal-header, .ant-modal-footer {
        background-color: $darky-white;
        border: 0;
    }

    .ant-modal-content {
        .ant-modal-body {
            .ant-confirm-body-wrapper {
                .ant-confirm-body {
                    .ant-confirm-title {
                        font-weight: bold;
                    }
                }
                
            }
        }
    }
}