@import '../../theme/partials/constants/colors';

.DrawerItemContainer {
    & + & {
        margin-top: 10px;
    }

    .DrawerItemTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        padding-bottom: 5px;

        & :global(.anticon) {
            line-height: 0;
            font-size: 18px;
            margin-left: 5px;
        }
    }

    .DrawerItemContent {
        padding: 10px;
        border-radius: 5px;
        background-color: $clean-white;
    }
}
