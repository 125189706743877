@import '../../theme/partials/constants/breakpoints';
.sceneContainerRowWrapper {
  background-color: var(--cf-scene-background);
  height: 100%;
  position: relative;
  padding: 20px;
  overflow-y: auto;
  .SceneContainerBodyWrapper {
    height: 100%;
    flex: auto;
    position: static;
  }

  .FlexColumnContainer {
    display: flex;
    flex-direction: column;
    @media #{$md-low} {
      display: block;
  }
  }
}