.SettingsContentHeader {
  align-items: center;
  border-bottom: var(--cf-settings-content-header-border);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-bottom: .5rem;
  margin-bottom: .5rem;
}

.SettingsContentHeaderWithGutter {
  margin-top: 2rem;
}
