@import '../../theme/partials/constants/colors';

.CrumbContainer {
    text-decoration: none;
    color: $clean-white;

    .CrumbTextContainer {
        max-width: 150px;
        overflow: hidden;
        // text-overflow: ellipsis; 
        white-space: nowrap;
    }
}