@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';

.NotSynchronizedBadge {
    :global(.ant-badge-dot) {
        background: $warning;
    }
}

.Synchronized {
    :global(.ant-badge-dot) {
        background-color: $primary;
    }
}

.ContentStatusSection {
    display: flex;

    .EnvironmentStatusButton {
        span {
            @media #{$md-low} {
            display: none;
            }
        }
    }

    .StatusContainer {
        .StatusButton {
        text-transform: capitalize;
        span {
            @media #{$md-low} {
            display: none;
            }
        }
        
        &.DifferentVersion {
            &, &:focus {
            color: $warning;
            border-color: $warning;
            }
            &:active, &:hover {
            color: darken($warning, 10);
            border-color: darken($warning, 10);
            }
        }

        &.NoVersion {
            &:hover, &:focus {
                color: $primary_text_color;
                background-color: transparent;
                border-color: $white-two;
            }
        }
        }
    }
      
}


.ContentStatusModal {
    
    @media #{$lg-up} {
        :global(.ant-modal) {
            width: 50%!important;
        }
    }
    @media #{$lg-low} {
        :global(.ant-modal) {
            width: 100%!important;
        }
        :global(.ant-table-thead) {
            display: none!important;
        }
    }
    :global(.ant-modal-title) {
        text-transform: capitalize;
    }
    
    :global(.ant-table-small > .ant-table-content > .ant-table-body) {
        margin: 0;
    }

    :global(.ant-table-tbody > tr) {
        height: 50px;
    }
    .StatusIcon {
        font-size: $fontSizeNavIcon;

        &.Primary {
            color: $primary
        }
        
        &.Warning {
            color: $warning;
        }
        
        &Error {
            color: $error;
        }
    }
}