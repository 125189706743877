.ant-checkbox-group {
  .ant-checkbox-group-item.ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: $gunmetal;
        top: -1px;
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background: $primary;
          border-color: $primary;
        }
      }
    }
  }
}
