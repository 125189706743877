.ContentDefinitionsSnippetsActionButton {
  margin-top: 2rem;
}

.ContentDefinitionsSnippetsListAvatar {
  background-color: #1e282c;
}

.ContentDefinitionsSnippetsListButton {
  font-weight: 400;
  font-size: .8125rem;
  margin-right: -.5rem;
}

.ContentDefinitionsSnippetsListMeta {
  align-items: center;
}

.ContentDefinitionsSnippetsNotificationIcon {
  color: #11a73b;
}

.ContentDefinitionsSnippetsSearch {
  margin: 0 0 1rem;
}

.ContentDefinitionsSnippetsSearch input:focus {
  box-shadow: 0 0 4px 2px rgba(2, 141, 193, 0.25);
}

.ContentDefinitionsSnippetsTagList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
