.ant-radio-group {
  .ant-radio-wrapper {
    &:hover {
      .ant-radio-inner {
        border-color: $primary;
      }
    }
  }
  .ant-radio {
    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: $primary;
        height: 18px;
        width: 18px;
        &:after {
          background-color: $primary;
          border-radius: 5px;
          width: 10px;
          height: 10px;
          top: 3px;
          left: 3px;
        }
      }
    }
  }
}
