$item-a-color: #ffffff;

.antd-menu {
  &-item > a {
    display: block;
    color: $item-a-color !important;
  }
  &-dark &-item,
  &-dark &-item-group-title,
  &-dark &-item > a {
    color: $item-a-color !important;
  }
}
