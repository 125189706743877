@import '../../../theme/partials/constants/variables';
@import '../../../theme/partials/constants/colors';

.PlanCardWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 30px 10px 0 10px;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(185, 185, 185, 0.4);
  
    @media only screen and (max-width: 1080px) {
      margin: 15px 0;
    }
  
    .PlanCardHeaderWrapper {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: $default-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0 15px;
      .PlanNameWrapper {
        width: 90%;
        text-align: center;
        color: $primary;
        font-size: $big-text-size + 6;
        line-height: 1.21;
        font-weight: 500;
      }
      .PlanDescriptionWrapper {
        width: 90%;
        display: block;
        text-align: center;
        color: $primary;
        font-size: $smallest-text-size;
        line-height: 1.2;
        font-weight: 300;
        margin-bottom: 1em;
      }
      .PlanPriceWrapper {
        color: $primary;
        width: 90%;
        text-align: center;
        font-size: $big-text-size + 2;
        line-height: 1.21;
        .PlanCostWrapper {
          color: $primary;
          font-weight: bold;
        }
        .PlanBilledYearlyWrapper {
          margin-top: 10px;
          font-size: $normal-text-size;
          font-weight: 500;
        }
      }
    }
  
    .PlanBenefitsWrapper {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-top: 15px;
      width: 70%;
  
      .IncludedBenefitsWrapper {
        margin-top: 10px;
      }
  
      .ElementsTitleContainer {
        color: $black-two;
        font-size: $small-text-size;
        font-weight: normal;
        padding-bottom: 15px;
        @include desktopLineHeight($small-text-size);
  
        @media only scree and (max-width: 1080px) {
          @include mobileLineHeight($small-text-size);
        }
      }
      .ElementTextContainer {
        color: $fade70-black-two;
        font-size: $smallest-text-size;
        font-weight: 300;
        line-height: normal;
        padding-bottom: 10px;
  
        @media only screen and (max-width: 1080px) {
          @include mobileLineHeight($smallest-text-size);
        }
      }
  
  
      p {
        margin: 0;
      }
    }
  
    .PlanCTAWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin-top: 30px;
      margin-bottom: 20px;
  
      .PlanSeeAllPlansDetailsCTA {
        text-align: center;
        margin-bottom: 20px;
        color: $primary;
        &:hover {
          color: darken($primary-color, 20%);
        }
      }
  
      .PlanTryItNowCTA {
        width: 100%;
        border: 2px solid $primary;
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        background: $primary;
        box-shadow: none;
        transition: none;
        height: 60px;
        padding: 0 30px;
        min-width: 120px;
        border-radius: 10px;
      }
    }
  }

  .SuggestedCard {
    @media only screen and (min-width: 1081px) {
      margin-top: 0;
    }
  
    .PlanCardHeaderWrapper {
      background-color: $primary;
  
      .PlanNameWrapper {
        color: $default-color;
        font-size: $biggest-text-size;
      }
      .PlanDescriptionWrapper {
        color: $default-color;
        font-size: $smallest-text-size + 2;
      }
      .PlanPriceWrapper {
        color: $default-color;
        .PlanCostWrapper {
          color: $default-color;
        }
      }
      }
  }