@import '../../theme/partials/constants/colors';

.LinkableContentLinkContent {
  width: 100%;
  text-decoration: none;
  color: $primary_text_color;
  &:hover, &:active, &:focus, &:visited {
    text-decoration: none;
    color: $primary_text_color;
  }

}
