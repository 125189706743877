.NoSpacesFound {
  align-items: center;
  background-color: #fff;
  color: #323232;
  display: flex;
  flex-flow: row;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.NoSpacesFoundCard {
  animation: slide-and-fade 900ms cubic-bezier(0.23, 0.72, 0.82, 0.82) 0s 1 forwards;
  padding: 2rem;
  text-align: center;
}

.NoSpacesFoundLogo {
  animation: slide-and-fade 900ms cubic-bezier(0.23, 0.72, 0.82, 0.82) 200ms 1 forwards;
  height: 100px;
  margin-bottom: 30px;
  opacity: 0;
}

.NoSpacesFoundText {
  animation: slide-and-fade 500ms cubic-bezier(0.23, 0.72, 0.82, 0.82) 800ms 1 forwards;
  font-size: .8rem;
  font-weight: 300;
  line-height: 1.618;
  opacity: 0;
}

.NoSpacesFoundButton {
  animation: slide-and-fade 500ms cubic-bezier(0.23, 0.72, 0.82, 0.82) 1000ms 1 forwards;
  margin-top: 40px;
  height: 40px;
  width: 200px;
  opacity: 0;
}

@keyframes slide-and-fade {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  80%{
    opacity: 1;
    transform: translateY(0);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}
