@import "../../theme/partials/constants/colors.scss";

.ContentDefinitionAddElement {
    display: block;
    margin: 1rem auto;
    text-align: center;
}

.ContentDefinitionAddElementDisabled {
    opacity: .54;
}

.ContentDefinitionElementContainer {
    display: flex;
    position: relative;
    :global(.ant-badge.ant-badge-not-a-wrapper) {
        position: absolute;
        right: 0;
        top: 0;
        :global(.ant-badge-dot) {
            height: 15px;
            width: 15px;
        }
    }

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    .ContentDefinitionElement {
        background: #fff;
        box-shadow: var(--cf-card-box-shadow), var(--cf-card-box-shadow);
        border-radius: var(--cf-card-border-radius);
        display: block;
        width: 100%;
    }
}

.ContentDefinitionConstraintIcon {
    cursor: help;

    & + & {
        margin-left: .5rem;
    }
}

.ContentDefinitionElementConstraints {
    margin-left: 1rem;
}

.ContentDefinitionElementSection {
    align-items: center;
    display: flex;
    flex-flow: row;
    padding: 0 1rem 0 0;
    user-select: none;

    .TooltipOverlay {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 1000;
    }
}

.TooltipStyle {
    z-index: 1001;
    :global(.ant-tooltip-content) {

        :global(.ant-tooltip-arrow) {
            visibility: hidden;
        }
        :global(.ant-tooltip-inner) {
            background: var(--cf-card-background-color);
            border-radius: var(--cf-card-border-radius);
            box-shadow: 0 11px 15px 2px rgba(0, 0, 0, 0.54);
            display: flex;
            flex-flow: column;
            min-width: 200px;
            transition: opacity 100ms ease-in-out, transform 200ms ease-in-out;
        }
    }

    .ButtonStyle {
        height: auto;
        box-shadow: initial;
        background: var(--cf-card-background-color);
        border: 0 none;
        color: rgba(0, 0, 0, .83);
        cursor: pointer;
        font-weight: 700;
        padding: 1rem;
        transition: all 300ms cubic-bezier(0.08, 0.89, 0.58, 0.97);
        text-align: left;

        &:last-child {
            color: $tomato
        }

        > :global(.anticon){
            margin-right: 1rem;
        }
    }

    .ButtonStyle:focus {
        outline: 0 none;
    }

    .ButtonStyle:disabled {
        cursor: initial;
        color: rgba(0, 0, 0, 0.25);
    }

    .ButtonStyle:hover:disabled {
        color: rgba(0, 0, 0, 0.25);
        background: initial;
    }

    .ButtonStyle:hover {
        background: rgba(0, 0, 0, .03);
        color: rgba(0, 0, 0, 1);
    }
}

.ContentDefinitionElementSectionChildren {
    background-color: rgba(0, 0, 0, .05);
    flex-flow: column;
    padding: 1rem 2rem 1rem 2rem;
}

.ContentDefinitionElementAvatar {
    margin: 0 1rem;
}

.ContentDefinitionElementContent {
    display: flex;
    flex-flow: column;
    flex: 1 0 auto;
}

.ContentDefinitionElementFieldType {
    display: flex;
    flex-flow: row;
    flex: 1 0 auto;
}

.ContentDefinitionElementDisabled {
    filter: grayscale(90%) opacity(50%);
}

.ContentDefinitionElementHandle {
    background: linear-gradient(90deg, #fefefe 0%, #f0f0f0 100%);
    border-bottom-left-radius: var(--cf-card-border-radius);
    border-top-left-radius: var(--cf-card-border-radius);
    padding: 1.6rem;

    .ContentDefinitionElementDisabled & {
        cursor: not-allowed;
    }
}

.ContentDefinitionElementDraggable {
    cursor: move;
    padding: 1rem;
}

.ContentDefinitionElementSectionExpanded .ContentDefinitionElementHandle {
    border-bottom-left-radius: 0;
}

.ContentDefinitionElementSettings {
    color: $primary;
    cursor: pointer;
    font-weight: 700;

    .ContentDefinitionElementDisabled & {
        cursor: not-allowed;
    }
}

.DefinitionSchemaEditor {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.DefinitionSchemaEditorGutter {
    padding: 0 10px;
}

.BadgeDotStyle {
    :global(.ant-badge-dot) {
        height: 15px;
        width: 15px;
    }
}
