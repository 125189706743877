.SubscriptionPlanSummary {
    display: flex;
    flex-flow: column;
}

.SubscriptionPlanSummaryPlan {
    color: #000;
    font-size: 1.953rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: .5rem;
}

.SubscriptionPlanSummaryFee {
    color: rgba(#000000, .89);
    font-size: 1.563rem;
    line-height: 1;
    margin-bottom: 1.5rem;
}

.SubscriptionPlanSummaryRenewalDate {
    color: rgba(#000000, .63);
    font-size: .8rem;
    font-style: italic;
}
