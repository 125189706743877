@import '../../../theme/partials/constants/colors';

.FragmentFieldPopoverOverride {
  :global(.ant-popover) {
    max-width: 75%;
  }
}

.FragmentFieldLabelWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.FragmentFieldErrorLabels {
  color: $tomato;
}

.FragmentFieldErrorBorder {
  border: 1px solid $tomato;
}

.FragmentFieldChangeSelection {
  margin-left: 5px;
}

.FragmentFieldRemoveFragment {
  margin-left: 5px;
}

.FragmentFieldWrapper {

  :global(div.ant-collapse) {
    :global(div.ant-collapse-item.ant-collapse-item-disabled.ant-collapse-no-arrow) {
      :global(div.ant-collapse-header) {
        cursor: pointer;
        color: black;
        background-color: rgba(0, 88, 255, 0.06);
      }
    }

    :global(div.ant-collapse-header) {
      cursor: pointer;
      color: black;
      background-color: rgba(0, 88, 255, 0.06);
    }

    :global(div.ant-collapse-content) {
      background-color: #f7f7f8;
    }
  }

  .FragmentFieldAddFragmentWrapper {
    display: flex;
    padding: 10px;

    .FragmentFieldAddFragment {
      > i {
        vertical-align: middle;
      }
    }
  }

  .FragmentFieldPanelHeaderWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 12px;

    .FragmentName {
      font-style: italic;
      margin-right: 5px;
    }

    .FragmentFieldPanelIconWrapper {
      display: flex;
      align-items: center;

      .FragmentFieldLabelWithoutHint {
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
      }

      .FragmentFieldPanelIconExclamation {
        color: $tomato;
        font-size: 20px;
        margin-right: 15px;
      }

      .FragmentFieldPanelIconClose {
        font-size: 20px;
        margin-right: 5px;
      }

      .FragmentFieldPanelIconOpen {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
}
