.ExpandableCellContainer {
    text-align: left;
    .ExpandableCellTitle {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 20px;
    }
    .ExpandableCellTags {
        color: #263238;
        opacity: 0.65;
        font-size: 12px;
    }
    .ExpandableCellToggle {
        text-align: left;
        color: black;
        text-decoration: underline;
        border: none;
        &:after {
            animation: none;
        }
    }
}
