@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';

.mediaImageContainer {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-bottom: 3px $gunmetal double;

  .MediaPreviewRawFile {
      font-size: 60px;
  }

  img {
    border-radius: 5px;
  }

  .MediaPreviewImage {
    height: 100%;
    max-width: 100%;

    @media #{$xs-only} {
      max-height: 100%;
      height: auto;
      max-width: 100%;
      width: 100%;
    }
  }
}

.mediaDataContainer {
  height: 50%;
overflow-y: auto;
  margin-top: 24px;
  .dataFieldContainer {
    padding: 10px;

    .dataKeyContainer {
      font-weight: bold;
    }
  }
}