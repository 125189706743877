@import '../../theme/partials/constants/colors.scss';
@import '../../theme/partials/constants/breakpoints.scss';

.MediaContainer {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: $clean-white;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    .MediaInfoContainer {
        position: relative;
        height: 35%;
        width: 100%;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 20px 30px;

        .MediaInfoTitle {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .MediaTagsContainer {
            .MediaTag {
                opacity: 0.6;
            }
            .MediaTag + .MediaTag {
                padding-left: 3px;
            }
        }

        .MediaSelected {
            position: absolute;
            right: 10px;
            bottom: 10px;
            font-size: 25px;
            color: $primary;
        }
    }

    .MediaImg {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 65%;
        width: 100%;
        object-fit: cover;
    }
    
}
