.Paper {
    border-radius: var(--cf-card-border-radius);
    box-shadow: var(--cf-card-box-shadow);
    background-color: #fff;
}

.PaperGutter {
    padding: 1rem;
}

.Paper + .Paper {
    margin-top: 16px;
}
