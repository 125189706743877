@import '../../../theme/partials/constants/variables';
.DynamicFieldContainer {
    display: flex !important;

    .InputContainer {
        width: 100%;
    }

    .LocaleTag {
        margin-bottom: 0;
        font-size: $fontSizeDefault;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}