@import '../../theme/partials/constants/colors';

.MenubarLayoutNavbar {
  width: 100%;
  min-height: 100%;
}

.MenubarLayoutContainer {
  max-width: 960px;
  margin: 0 auto;
  background-color: var(--cf-scene-background);
  padding: 20px;
  height: 100%;
  width: 100%;
  flex-direction: row;

  .MenubarLayoutContent {
    padding: 0 20px 12px 10px;
    height: 100%;
  }

  [class*="Breadcrumb_BreadcrumbContainer"] {
    padding-left: 0;
  }
}
