.ContainerInfoSection {
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 100%;
    position: sticky;
}

.ContainerInfoSectionGutterBottom {
    margin-bottom: 2rem;
}

.ContainerInfoSectionGutterLeft {
    padding-left: 2rem;
}

.ContainerInfoSectionGutterRight {
    padding-right: 2rem;
}

.ContainerInfoSectionGutterTop {
    margin-top: 2rem;
}
