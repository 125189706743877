.ListContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    :global(.ant-spin-nested-loading) {
        flex-grow: 1;
        overflow: auto;
        margin-bottom: 10px;
    }
    :global(.ant-list-pagination) {
        margin-top: 0px;
        text-align: left;
    }
}