.Container {
    display: flex;

    > button {
        width: 32px!important;
        height: 32px!important;
        margin-left: 10px;
    }

}
.ModalContainer {
    .ListContainer {
        margin-top: 8px;
        .ListItem {
            cursor: pointer;    
        }
    }
}
