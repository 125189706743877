@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/breakpoints';

.CloudinaryModalPreviewContainer {
    height: 100%;
    padding: 0 24px;

    .CloudinaryModalPreviewColumns {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        
        img, video {
            max-width: 100%;
            max-height: 100%;
            border-radius: 5px;
        }
    }

    .CloudinaryModalPreviewColumns + .CloudinaryModalPreviewColumns {
        height: 90%;
        padding: 10px 0;
    }

    .CloudinaryModalPreviewCTAContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .CloudinaryModalPreviewCTAButton {
            text-transform: uppercase;
            width: 200px;
        }
    }

    @media #{$md-low} {
        .CloudinaryModalPreviewColumns + .CloudinaryModalPreviewColumns {
            height: 50%;
        }

        .CloudinaryModalPreviewCTAContainer {
            .CloudinaryModalPreviewCTAButton {
                width: 40%;
            }
        }
    }

    .CloudinaryModalPreviewInputContainer {
        p {
            font-weight: bold;
            font-size: 16px;
            padding-bottom: 5px;
        }
        .CloudinaryModalPreviewErrorLabel {
            font-weight: normal;
            font-size: 14px;
            padding-top: 7px;
            color: $tomato;
        }
    }

    .CloudinaryModalPreviewInputError, .CloudinaryModalPreviewErrorLabel:focus {
        border-color: $tomato!important;
    }

    .CloudinaryModalPreviewInputContainer + .CloudinaryModalPreviewInputContainer {
        padding-top: 20px;
    }
}