$top-nav-height: 64px;
$brand-white: #fff;

$sidebar-width-height: 64px;
$menubar-width: 250px;

$minHeader: 126px;
$fullHeader: 342px;

$fontSizeSmall: 12px;
$fontSizeDefault: 14px;
$fontSizeLarge: 16px;
$fontSizeExtraLarge: 18px;

$fontSizeNavIcon: 24px;

$fontSizeHeader: $fontSizeExtraLarge;

$defaultBorderRadius: 5px;



$bright-light-blue: #2ddfff;
$bright-light-blue-40: fade-out($bright-light-blue, 0.6);
$cloudy-blue: #aeb8cc;
$black: #2f2e2d;
$purpleish-blue: #674bff;
$bright-purpleish-blue-40: fade-out($purpleish-blue, 0.6);
$turquoise: #01b9b2;
$bright-turquoise-40: fade-out($turquoise, 0.6);
$fade10-turquoise: fade-out($turquoise, 0.9);
$pastel-blue: #9aaeff;
$fade10-pastel-blue: fade-out($pastel-blue, 0.9);
$electric-blue: #0058ff;
$night-blue: #040a2f;
$tealish: #27bdd8;
$black-two: #000000;
$fade70-black-two: fade-out($black-two, 0.3);
$deep-sea-blue: #025186;
$dark-blue-grey: #13293d;
$cornflower: #6c63ff;
$purplish-grey: #65617d;
$dusk: #3f3d56;

$primary-color: $electric-blue;
$fade6-primary-color: fade-out($primary-color, 0.94);
$fade10-primary-color: fade-out($primary-color, 0.9);
$fade20-primary-color: fade-out($primary-color, 0.8);
$fade50-primary-color: fade-out($primary-color, 0.5);
$fade60-primary-color: fade-out($primary-color, 0.4);
$secondary-color: bright-light-blue;
$default-color: white;

$custom-link-color: $primary-color;
$custom-link-color-hover: darken($primary-color, 15%);
$custom-link-color-active: darken($primary-color, 20%);

$border-radius: 10px;

$smallHeaderHeight: 100px;
$fullHeaderHeight: 140px;

$defaultSectionMaxWidth: 80%;

// font-sizes
$important-text-size: 50px;
$bigger-text-size: 40px;
$biggest-text-size: 32px;
$big-text-size: 22px;
$normal-text-size: 18px;
$small-text-size: 16px;
$smallest-text-size: 12px;
@mixin desktopLineHeight($font-size){ line-height: $font-size + 10; }
@mixin mobileLineHeight($font-size){ line-height: $font-size + 8; }

// ******** sections ********
$desktopSectionTitleSize: $biggest-text-size;
$desktopSectionSubtitleSize: $small-text-size;
@mixin DesktopSectionTitleFont($color: $black-two, $weight: normal){
  @include desktopLineHeight($biggest-text-size);
  font-family: Montserrat, sans-serif;
  font-size: $desktopSectionTitleSize;
  letter-spacing: normal;
  font-weight: $weight;
  color: $color;
}
@mixin DesktopSectionSubtitleFont($color: $fade70-black-two){
  @include desktopLineHeight($desktopSectionSubtitleSize);
  font-family: Montserrat, sans-serif;
  font-size: $desktopSectionSubtitleSize;
  letter-spacing: normal;
  font-weight: 300;
  color: $color;
}

$mobileSectionTitleSize: $normal-text-size;
$mobileSectionSubtitleSize: $small-text-size;
@mixin MobileSectionTitleFont($color: $black-two){
  @include mobileLineHeight($mobileSectionTitleSize);
  font-family: Montserrat, sans-serif;
  font-size: $mobileSectionTitleSize;
  letter-spacing: normal;
  font-weight: 500;
  color: $color;
}
@mixin MobileSectionSubtitleFont($color: $fade70-black-two){
  @include mobileLineHeight($mobileSectionSubtitleSize);
  font-family: Montserrat, sans-serif;
  font-size: $mobileSectionSubtitleSize;
  letter-spacing: normal;
  font-weight: 300;
  color: $color;
}

// ******** important contents ********
$desktopImportantContentTitleSize: $important-text-size;
$mobileImportantContentTitleSize: $biggest-text-size;
@mixin DesktopImportantContentTitleFont($color: $black-two){
  @include desktopLineHeight($desktopImportantContentTitleSize);
  font-family: PTSerif, sans-serif;
  font-size: $desktopImportantContentTitleSize;
  letter-spacing: normal;
  font-weight: normal;
  color: $color;
}
@mixin MobileImportantContentTitleFont($color: $black-two){
  @include mobileLineHeight($mobileImportantContentTitleSize);
  font-family: PTSerif, sans-serif;
  font-size: $mobileImportantContentTitleSize;
  letter-spacing: normal;
  font-weight: normal;
  color: $color;
}

// ******** contents ********
$desktopContentTitleSize: $big-text-size;
$desktopContentDescriptionSize: $small-text-size;
@mixin DesktopContentTitleFont($color: $black-two){
  @include desktopLineHeight($desktopContentTitleSize);
  font-family: Montserrat, sans-serif;
  font-size: $desktopContentTitleSize;
  letter-spacing: normal;
  font-weight: 500;
  color: $color;
}
@mixin DesktopContentDescriptionFont($color: $fade70-black-two, $weight: 300){
  @include desktopLineHeight($desktopContentDescriptionSize);
  font-family: Montserrat, sans-serif;
  font-size: $desktopContentDescriptionSize;
  letter-spacing: normal;
  font-weight: $weight;
  color: $color;
}

$mobileContentTitleSize: $small-text-size;
$mobileContentDescriptionSize: $smallest-text-size;
@mixin MobileContentTitleFont($color: $black-two){
  @include mobileLineHeight($mobileContentTitleSize);
  font-family: Montserrat, sans-serif;
  font-size: $mobileContentTitleSize;
  letter-spacing: normal;
  font-weight: 500;
  color: $color;
}
@mixin MobileContentDescriptionFont($color: $fade70-black-two, $weight: 300){
  @include mobileLineHeight($mobileContentDescriptionSize);
  font-family: Montserrat, sans-serif;
  font-size: $mobileContentDescriptionSize;
  letter-spacing: normal;
  font-weight: $weight;
  color: $color;
}


@mixin borderRadius(){
  border-radius: $border-radius;
}
@mixin border($color){
  border: 2px solid $color;
}
@mixin highlightedText($color){
  background-image: linear-gradient(to right, fade-out($color, 0.2), fade-out($color, 0.2));
  background-position: 0 0.7em;
  background-repeat: repeat-x;
  background-size: 1em 1em;
}
@mixin linkStyles(){
  color: $custom-link-color;
  &:hover {
    color: $custom-link-color-hover
  }
}
@mixin sliderDotsColor($color){
  .CarouselContainer {
    > div {
      .carousel.carousel-slider {
        .control-dots {
          .dot {
            background-color: $color;
            &.selected {
              background-color: $color;
            }
            &:not(.selected) {
              background-color: initial;
              border: 1px solid $color
            }
          }
        }
      }
    }
  }
}

