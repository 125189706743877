@import '../../../theme/partials/constants/colors';

:global(.ant-form-item-required) {
    display: flex;
    align-items: center;
}

.PublicationRangeFormItemLabel {
    display: flex;
    align-items: center;

    :global(.anticon) {
        margin-left: 5px;
        font-size: 18px;
        line-height: 0 !important;
    }

    .PublicationRangeOnlineDateIcon {
        color: $light-moss-green
    }

    .PublicationRangeOfflineDateIcon {
        color: $tomato
    }
}

.PublicationRangePickerStyle {
    width: 100%!important;
}
