@import '../../theme/partials/constants/breakpoints';

.FloatingInputModalWrap {
    :global(.ant-modal) {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        width: 100%!important;
        height: 100%;
        position: relative;
        :global(.ant-modal-content) {
            position: absolute;
            bottom: 40px;
            right: 40px;
            width: 400px;

            @media #{$md-low} {
                bottom: 30px;
                right: 10px;
            }

            :global(.ant-modal-body), :global(.ant-modal-header) {
                background-color: white;
            }
        }
    }


    .FloatingInputForm {
        display: flex;
        align-items: flex-start;

        .FloatingInputFormItem {
            flex-grow: 1;
            height: 80px;
        }

        .FloatingInputConfirm {
            margin-top: 5px;
            min-width: 50px;
            text-transform: uppercase;
        }
    }
}
