@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/breakpoints';

.CloudinaryPublicIdModalPreviewContainer {
  height: 100%;
  padding: 0 24px;

  .CloudinaryPublicIdModalPreviewColumns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 5px;
    }
  }

  .CloudinaryPublicIdModalPreviewColumns + .CloudinaryPublicIdModalPreviewColumns {
    height: 90%;
    padding: 10px 0;
  }

  .CloudinaryPublicIdModalPreviewCTAContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .CloudinaryPublicIdModalPreviewCTAButton {
      text-transform: uppercase;
      width: 200px;
    }
  }

  @media #{$md-low} {
    .CloudinaryPublicIdModalPreviewColumns + .CloudinaryPublicIdModalPreviewColumns {
      height: 50%;
    }

    .CloudinaryPublicIdModalPreviewCTAContainer {
      .CloudinaryPublicIdModalPreviewCTAButton {
        width: 40%;
      }
    }
  }

  .CloudinaryPublicIdModalPreviewInputContainer {
    p {
      font-weight: bold;
      font-size: 16px;
      padding-bottom: 5px;
    }
    .CloudinaryPublicIdModalPreviewErrorLabel {
      font-weight: normal;
      font-size: 14px;
      padding-top: 7px;
      color: $tomato;
    }
  }

  .CloudinaryPublicIdModalPreviewInputError, .CloudinaryPublicIdModalPreviewErrorLabel:focus {
    border-color: $tomato!important;
  }

  .CloudinaryPublicIdModalPreviewInputContainer + .CloudinaryPublicIdModalPreviewInputContainer {
    padding-top: 20px;
  }
}