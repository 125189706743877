@import '../../theme/partials/constants/colors.scss';
.RawFile {
    display: flex;
    flex-direction: column;
    height: 100%; 
    align-items: center; 
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px $text-color;

    .RawFileName {
        padding: 20px 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
    }

    .RawFileIcon {
        font-size: 100px;
    }
}