@import "../../../theme/partials/constants/variables";
@import "../../../theme/partials/constants/breakpoints";

.sceneDashboardHeader {
  height: $fullHeader;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-attachment: fixed;

  .headerTitle {
    color: #fff;
    padding-bottom: 35px;
    padding-left: 30px;
  }

  .headerLinks {
    display: flex;
    padding-left: 30px;
    padding-bottom: 35px;

    .headerLinksElement {
      list-style-type: none;
      font-size: 16px;
      padding-right: 40px;
      > a {
        color: #fff;
      }
    }
  }

  @media #{$xs-only} {
    height: auto;
    padding: 20px;
    .headerTitle {
      text-align: center;
      padding: 20px 0 40px 0;
    }

    .headerLinks {
      display: block;
      padding: 0;

      .headerLinksElement{
        padding: 0 0 10px 0;
        margin: 0;
        text-align: center;
      }
    }
  }
}

.SceneDashboardContents {
  height: 90px;
  background-color: #fff;

  @media #{$sm-low} {
    height: auto;
    align-items: center;
    .ContentsTeamMembers {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .ContentsTeamMembers {
    display: flex;
    align-items: center;
    padding: 10px;
  }
}