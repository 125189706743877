@import '../../theme/partials/constants/breakpoints';

.TabTitleContainer {
    display: flex;
    justify-content: flex-end;
    .TabTitleIcon {
        padding-left: 10px;
        font-size: 18px;

        :global(.anticon) {
            margin-right: 0;
        }
    }

    @media #{$sm-low} {
        .TabTitleIcon {
            padding-left: 0;
            font-size: 25px;
        }
        .TabTitleLabel {
            display: none;
        }
    }
}