@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';

.MediaActionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    
    
    .MediaActionsButton {
        width: 50px;
        font-size: 25px;
        border-radius: 50%;
        height: 50px;
        box-shadow: 2px 6px 5px 0px rgba(0, 0, 0, 0.5);
        
        &.MediaActionsButtonToggled {
            color: $gunmetal;
            background-color: $primary;
            box-shadow: none;
        }
    }
    
    .MediaActionsButton + .MediaActionsButton {
        margin-top: 10px;
    }
}


@media #{$sm-low} {
    .MediaActionsNotificationCentered {
        margin-left: 15px;
    }
}