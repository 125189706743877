@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';

.SpaceListWrapper {
  @media #{$md-low} {
    height: 100%;
  }
  display: flex;
  flex-direction: column;

  :global(.ant-spin-nested-loading) {
    flex-grow: 1;

    @media #{$md-low} {
      height: 100%;
      overflow-y: auto;
    }
  }

  .SpaceListItem {
    border-bottom: 1px solid $list-item-separator!important;
    &:hover {
      background-color: #f6f6f6;
    }

    .SpaceListItemMeta {
      align-items: center;
    }
  }
}
