@import '../../theme/partials/constants/colors';

$border-color: $white-two;

.Container {
    border-radius: 0;
    background-color: $clean-white;
    border-top: 0px solid $border-color;
    max-height: 350px;
    overflow-y: auto;
}

.TranslationStatusContainer {
    > .Container:first-child {
        border-top: 1px solid $border-color;
        border-radius: var(--cf-card-border-radius);
    }

    .SaveButton {
        text-transform: uppercase;
        margin-top: 10px;
    }
    .CleanButton {
        text-transform: uppercase;
        margin-top: 10px;
    }
}

.Toggle {
    display: flex;
    .Button {
        flex-grow: 1;
        height: 46px;
        width: 50%;
        border-bottom-left-radius: 0!important;
        border-bottom-right-radius: 0!important;
        text-transform: capitalize;
    }
}

