@import '../../../theme/partials/constants/colors';

.FullHeight {
  height: 100%;
}

.FormContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  .JsonEditorRowContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .SchemaEditorColContainer {
      flex: 1;
    }
    .SchemaEditorWithoutErrorContainer {
      color: red;
      margin-bottom: 25px;
    }

    .SchemaEditorWithErrorContainer {
      margin-bottom: 6px
    }
  }
}
