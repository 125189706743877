@import '../../theme/partials/constants/colors.scss';
@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/breakpoints';

.AsideItemDefault {
    // padding: 16px 16px 0 0;
    margin: 1rem 0;
    font-size: $fontSizeDefault;

    // @media #{$sm-low} {
    //     padding-left: 16px;
    // }

    .AsideItemHeader {
        padding: 12px 0; 
        text-align: center;
        text-transform: uppercase;
    }
}
