@import '../../../theme/partials/constants/colors';
@import '../../../theme/partials/constants/breakpoints';

.MediaListSceneTitle {
  height: 10%;
  padding-top: 25px;
  padding-left: 25px;

  @media #{$xs-only} {
    height: 15%;
  }
}

.MediaLisSceneDrawer {
  :global(.ant-drawer-content-wrapper){
    background-color: $darky-white;

    @media #{$xs-only} {
      width: 300px!important;
    }

    @media #{$sm-only} {
      width: 450px!important;
    }

    @media #{$md-up} {
      width: 700px!important;
    }

    @media #{$xl-up} {
      width: 900px!important;
    }

    :global(.ant-drawer-body) {
      height: 100%;
    }
  }
}

.MediaListSceneBody {
  height: 100%;
}