@import '../../../theme/partials/constants/colors';

$border-color: $white-two;

.LocaleLabel {
    grid-area: description;
}

.Selection {
    grid-area: selection;
}

.LocaleItemContainer {
    height: 46px;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: 46px;
    grid-auto-columns: auto;
    gap: 0px 0px;
    grid-auto-flow: column;
    grid-template-areas:
        "selection description actions";
      
    border: 1px solid $border-color;
    border-radius: 5px;
    overflow: hidden;
    .Selection {
        grid-area: selection;
        width: 46px;
        height: 46px;
        border-right: 1px solid $border-color;
        position: relative;
        box-sizing: content-box;
        cursor: pointer;
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 0;
            background-color: $clean-white;
            transition: color .15s linear, height .15s linear, width .15s linear, background-color .2s linear, border-radius .15s linear .1s;
            border-radius: 50%;
        }
        
        .IconCheck, .IconToSave, .IconWaiting {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
        }

        .IconCheck, .IconToSave, .IconWaiting {
            color: $clean-white;
        }

        .IconToSave {
            color: $text-color;
        }

        .IconWaiting {
            color: $border-color;
        }
    }
    .Selection.ToSave {
        &::before {
            background-color: $darky-white;
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
    .Selection.Selected {
        &:before {
            background-color: $primary;
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
        .IconCheck {
            display: block;
        }
    }
    &.Disabled {
        .LocaleLabel, .ActionsContainer {
            background-color: $darky-white!important;
        }
    }
    .LocaleLabel {
        padding-left: 10px;
        grid-area: description;

        position: relative;
        overflow-x: hidden;
        max-width: 100%;

        p {
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

    }

    .LocaleLabel, .ActionsContainer {
        padding-right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .ActionsContainer {
        grid-area: actions;
        justify-self: right;
        .EditAction {
            // border-color: $border-color;
            // border-radius: 0;
            width: 24px;
            height: 24px;
            padding: 0;
            // i {
            //     color: $primary;
            // }
        }

        .TagContainer {
            padding-right: 5px;
            .Synchronized {
                background-color: $emerald;
                color: $clean-white;
                border: 0;
            }
    
            .NotSynchronized {
                background-color: $warning;
                color: $clean-white;
                border: 0;
            }
    
            .Original {
                background-color: $primary;
                color: $clean-white;
                border: 0;
            }
        }
    }

    :global(.ant-tag) {
        margin-bottom: 0px;
        margin-right: 0px;
    }
}

.ToggleContainer {
    cursor: pointer;
    display: flex;
    .ToggleSelection {
        height: 46px;
        width: 46px;
        border-left: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 0;
            background-color: $clean-white;
            transition: color .15s linear, height .15s linear, width .15s linear, background-color .2s linear, border-radius .15s linear .1s;
            border-radius: 50%;
        }
        .DeleteIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
        }
    }
    &.Toggled {
        .ToggleSelection {
            &:before {
                background-color: $red-warning;
                width: 100%;
                height: 100%;
                border-radius: 0;
            }

            .DeleteIcon {
                color: $clean-white;
            }
        }
    }
    .LocaleItemContainer {
        flex: 1;
        pointer-events: none;
        .Selection {
            pointer-events: none;
        }
    }
}

.LocaleItemContainer + .LocaleItemContainer {
    border-top: 0;
    border-radius: 0;
}

:global(.translation-collapse-header) {
    padding: 0!important;
}

:global(.translation-collapse-content-box) {
    padding: 0!important;
    .LocaleLabel {
        padding-left: 20px;
        &::before {
            content: '';
            border-left: 1px solid black;
            height: 100%;
            position: absolute;
            left: 10px;
            border-color: $light-gunmetal;
        }

        &::after {
            content: '';
            border-top: 1px solid black;
            width: 8px;
            position: absolute;
            top: 50%;
            left: 10px;
            border-color: $light-gunmetal;
        }
    }

    .LocaleItemContainer:last-child {
        .LocaleLabel {
            &::before {
                height: 50%;
                top: 0;
            }
        }
    }
}

:global(.translation-collapse) {
    .LocaleItemContainer {
        border-radius: 0px;
        border-top: 0;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
    }

    > *:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
