.SubscriptionSpaceCountIndicator {
    border-radius: 2rem;
    display: flex;
    flex-flow: column;
    text-align: center;
    padding: 1rem;
}

.SubscriptionSpaceCountIndicatorShadow {
    @extend .SubscriptionSpaceCountIndicator;
    background-color: #fff;
    box-shadow: 
        0px 5px 6px -3px rgba(0,0,0,0.2),
        0px 9px 12px 1px rgba(0,0,0,0.14),
        0px 3px 16px 2px rgba(0,0,0,0.12)
    ;
}

.SubscriptionSpaceCountIndicatorButton {
    display: block;
    font-size: .75rem;
    font-weight: 400;
    margin: 1.5rem auto 0;
}

.SubscriptionSpaceCountIndicatorButtonContainer {
    display: block;
}

.SubscriptionSpaceCountIndicatorCounter {
    font-size: .75rem;
}