.Invite {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 100vh;
}

.InviteTitle {
    color: #000;
    font-size: 3.052rem;
    font-weight: 700;
    margin-bottom: 1.1em;
}

.InviteSubTitle {
    font-size: 1.563rem;
    font-weight: 400;
    margin-bottom: 1.1em;
}

.InviteText {
    color: rgba(0, 0, 0, 0.63);
    font-size: 1rem;
    font-style: italic;
    line-height: 1.618;
    margin-bottom: 1.1em;
}
