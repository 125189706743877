.SideBar {
    overflow: auto;
    left: 0;
    z-index: 10;
    box-shadow: 2px 0 6px rgba(0,21,41,.35);
    .MenuContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: all .2s;
        z-index: 10;

        .SideBarItemDefault {
            margin-top: 0;
            margin-bottom: 0;
        }

        .SideBarItemBottom {
            margin-top: auto;
        }
    }
};
