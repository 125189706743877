@import "../../theme/partials/constants/colors.scss";

.CCTablePaginationContainer {
    display: flex;
    justify-content: flex-end;
}

.CCTableDataContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-end;
    :global(.ant-table-wrapper) {
        height: 100%;
        :global(.ant-spin-nested-loading) {
            height: 100%;
            :global(.ant-spin-container) {
                height: 100%;
                :global(.ant-table) {
                    height: 100%;
                    :global(.ant-table-content) {
                        height: 100%;
                        :global(.ant-table-scroll) {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            :global(.ant-table-header) {
                                z-index: 1;
                                padding-bottom: 0;
                                margin-bottom: 0;
                                overflow: initial;
                                :global(.ant-table-thead) {
                                    tr {
                                        transition: all .3s;
                                        border-bottom: 1px solid #e5e5e5;
                                    }
                                }
                            }
                            :global(.ant-table-body) {
                                max-height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

//TODO: this class will make scrollbar inside tables always visible enable className prop inside CCTable component
.CCTable {
    :global(.ant-spin-nested-loading) {
        :global(.ant-spin-container) {
            :global(.ant-table) {
                :global(.ant-table-content) {
                    :global(.ant-table-scroll) {
                        :global(.ant-table-body::-webkit-scrollbar-track) {
                            background-color: transparent;
                        }
                        :global(.ant-table-body::-webkit-scrollbar) {
                            width: 10px;
                            background-color: transparent;
                        }
                        :global(.ant-table-body::-webkit-scrollbar-thumb) {
                            border-radius: 10px;
                            box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }
}
