.FieldRepositoryOptionContainer {
  display: flex;
  align-items: center;

  .FieldRepositoryOptionIcon {
    height: 15px;
    width: 15px;
  }

  .FieldRepositoryOptionLabel {
    margin-left: 5px;
  }
}
