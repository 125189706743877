@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/breakpoints';

.EditIcon {
    font-size: $fontSizeNavIcon;
    position: absolute;
    right: 16px;
    cursor: pointer;
}

.ButtonContainer {
    text-align: center;
    margin-top: 16px;
    button {
        text-transform: uppercase;
    }
}

.LoaderContainer {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EmptyBillingContainer {
    display: flex;
    align-content: center;
    justify-content: center;
    min-height: 200px;
    flex-direction: column;
    p {
        text-align: center;
    }
}

.BillingDrawer {
    :global(.ant-drawer-content-wrapper) {
        width: 50%!important;

        @media #{$sm-low} {
            width: 100%!important;
        }
    }
}