@import '../../../theme/partials/constants/colors.scss';

.AddFormContainer {
  .AddContentFormWrapper {
    padding-bottom: 25px
  }

  .FormTitle {
    margin-bottom: 20px;
  }

  .SubmitButton {
    text-transform: uppercase;
    background-color: $gunmetal;
    color:  $clean-white;
    height: 36px!important;
    &:disabled {
      text-transform: uppercase;
      color: rgba($text-color, 0.25);
      background-color: $lighty-white;
      height: 36px!important;
    }
  }

  .BackButton {
    text-transform: uppercase;
    background-color: $clean-white;
    height: 36px!important;
  }
}