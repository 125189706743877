.ant-input-affix-wrapper {
  .ant-input {
    min-height: auto;
  }
}

.input-pattern {
  border-radius: 4px;
}

.input-active-pattern {
  &:focus {
    border-color: $primary;
    box-shadow: 0 0 0 2px rgba(255, 196, 0, 0.2);
  }
  &:hover {
    border-color: $primary;
  }
}

.input-active-pattern {
  &:focus {
    border-color: $primary;
    box-shadow: 0 0 0 2px rgba(255, 196, 0, 0.2);
  }
}

.ant-input {
  border-radius: 4px;
  @extend .input-active-pattern;
}

.ant-input-search {
  .ant-input:not(.ant-input-disabled) {
    @extend .input-active-pattern;
  }
  .ant-input-suffix {
    transform: none;
    top: 7px;
  }
}
