@import '../../theme/partials/constants/variables';

.RepositoryStatsContainer {
    font-size: $fontSizeDefault;
    display:flex;
    flex-wrap: wrap;

    .RepositoryStatsStat {
        display: flex;
        align-items: center;
        
        .RepositoryStatsCount {
            font-weight: bold;
            padding-left: 7px;

        }
    }

    .RepositoryStatsStat + .RepositoryStatsStat {
        padding-left: 46px;
    }

}