@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/_colors.scss';

.MediaDetailsTagsWrapper {
  .MediaDetailsTag {
    background-color: $primary;
    color: $clean-white;
  }
  .MediaDetailsTagsColumn {
    padding: 10px;
    .MediaDetailsEmptyTags {
      display: table-cell;
      vertical-align: middle;
      height: 32px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .MediaDetailsEditTagsColumn {
    .MediaDetailsEditTagsIcon {
      color: $primary;
      font-size: 30px;
      cursor: pointer;
    }
  }
}

.MediaDetailsDataWrapper {
  @media #{$sm-low} {
    flex-flow: wrap-reverse;
  }

  .MediaDetailsActionColumn {
    padding: 10px;
    button {
      margin: 5px;
    }
  }

  .MediaDetailsInformationColumn {
    padding: 10px;
    .MediaDetailsInformationLabel {
      font-weight: bold;
      font-size: 15px;

      @media #{$sm-low} {
        text-align: center;
      }
    }

    .MediaDetailsInformationValue {
      font-size: 15px;

      @media #{$sm-low} {
        text-align: center;
      }
    }
  }
}