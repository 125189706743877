@import '../theme/partials/constants/colors';

.AppErrorBoundaryWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark;

  .AppErrorBoundaryContainer {
    height: 100%;
    display: flex;
    flex-direction: column;align-items: center;
    justify-content: space-evenly;
    line-height: 32px;
    padding: 20px;
    text-align: center;
    animation: slide-in 0.5s forwards;
    transform: translateY(100%);

    @keyframes slide-in {
      100% { transform: translateX(0%); }
    }

    .AppErrorBoundaryImageContainer {
      img {
        height: 180px;
      }
    }

    .AppErrorBoundaryMessageContainer {
      text-align: center;
      line-height: 45px;
      color: white;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .AppErrorBoundaryButton {
      height: 40px;
      width: 200px;
    }
  }
}