.YouTrialHasExpiredMessage {
    display: flex;
    flex-flow: column;
}

.YouTrialHasExpiredMessageTitle {
    font-size: 3.992rem;
    margin-bottom: 1rem;
}

.YouTrialHasExpiredMessageText {
    font-size: 1rem;
    margin-bottom: 2rem;
}

.YouTrialHasExpiredMessageChildren {
    display: block;
}