@import '../../../../theme/partials/constants/breakpoints';
@import '../../../../theme/partials/constants/colors';

.PartialsContainer {
    background: #fff;
    box-shadow: var(--cf-card-box-shadow);
    padding: 1rem;
    position: relative;
}

.SubscriptionDetailPlan {
    position: relative;
    height: 100%;
}

.SubscriptionDetailPlanContentRiseAbove {
    background-color: transparent;
    height: 100%;
    opacity: 0;
    left: 0;
    position: absolute;
    transform: translate3d(0, 10vh, 0);
    transition:
        background-color 400ms ease-in-out,
        opacity 400ms ease-in-out,
        transform 200ms ease-in-out;
    ;
    top: 0;
    width: 100%;
}

.SubscriptionDetailPlanContentRiseAboveButton {
    & + & {
        margin-left: 1rem;
    }
}

.SubscriptionDetailPlanContentRiseAboveContent {
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 3.052rem;
    }
}

.SubscriptionDetailPlanContentRiseAbovePlan {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.5rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
}

.SubscriptionDetailPlanContentRiseAboveVisible {
    background-color: #fff;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    z-index: 1;
}

.SubscriptionDetailPlanContent {
    display: flex;
    flex-flow: row;
    overflow-x: auto;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
    flex-direction: column;

    @media #{$sm-low} {
        flex-direction: row;
    }

    @media #{$md-up} {
        flex-wrap: nowrap;
    }

    .SubscriptionUpgradeContainer {
        display: flex;
    }

    .SubscriptionUpgradeContainer + .SubscriptionUpgradeContainer {
        margin-top: 20px;
    }
}

.SubscriptionTabTitle {
    color: #848d91;
    font-weight: 700;
}

.PlanCardInUpgrade {
    height: 100%;
    :global(.PlanBenefitsWrapper) {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }
}
