@import '../../theme/partials/constants/variables';

.ContentListFiltersListAppliedFilters {
  :global(.ant-list-item) {
    padding: 0;

    :global(.ant-list-item-meta) {
      align-items: center;
      max-width: 90%;

      :global(.ant-list-item-meta-content) {
        overflow: hidden;

        :global(.ant-list-item-meta-title) {
          max-width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

    }

    :global(.ant-list-item-action) {
      margin-left: 0;
      > li {
        padding: 0;
      }
    }
  }
}

.ArchivedUnarchivedSwitch {
  margin-right: 10px;
}

.ArchiveIconStyle {
  margin-left: 5px;
  font-size: $fontSizeLarge;
}
