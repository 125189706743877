@import '../../theme/partials/constants/colors.scss';

.ModalTitleLabel {
    font-size: 36px;
    padding-top: 35px;
}

.ModalTitleCancel {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 30px;
    cursor: pointer;
    color: $gunmetal;
}