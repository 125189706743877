@import '../../theme/partials/constants/colors';

.MenubarContainer {
  margin-right: 20px;
  align-self: flex-start;
  border-radius: var(--cf-card-border-radius);
  box-shadow: var(--cf-card-box-shadow);
  :global(.ant-menu.ant-menu-root.ant-menu-inline), :global(.ant-menu.ant-menu-root.ant-menu-inline-collapsed) {

    border-radius: var(--cf-card-border-radius);
    :global(.ant-menu-item) {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
      &:hover {
        color: initial;
        background: $darky-white;
      }
    }

    :global(.ant-menu-item.ant-menu-item-disabled) {
      cursor: initial;
      font-weight: bold;
      color: $gunmetal !important;
      &:hover {
        color: initial;
        background: initial;
      }
    }

    :global(.ant-menu-item.ant-menu-item-selected) {
      font-weight: bold;
      color: black;
      background-color: initial;
      border-right: unset;
      margin-top: 0;
      margin-bottom: 0;

      &:hover {
        background: $darky-white;
      }

      &:after {
        border-right: unset;
      }
    }
  }

  .MenubarTitle {
    font-size: 18px;
  }
}
