.ListWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  :global(.ant-spin-nested-loading) {
    height: 100%;

    :global(.ant-spin-container) {
      height: 100%;
      overflow-y: auto;
      padding: 0 10px;

      :global(.ant-list-item) {
        :global(.ant-list-item-meta) {
          align-items: center;
        }
      }

      .DefinitionName {
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .DefinitionMnemonicId {
        color: rgba(0, 0, 0, .55);
        font-style: italic;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.FooterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & button:not(:last-child) {
    margin-right: 10px;
  }
}
