@import '../../../theme/partials/constants/colors.scss';

.EditFormContainer {
  .EditFormContainerInfoSectionContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: flex-end;

    .EditFormLocaleInfoContainer {
      flex: auto;
    }

    .EditFormContainerInfoItem + .EditFormContainerInfoItem {
      margin-left: 20px;
    }
  }

  .FormTitle {
    margin-bottom: 20px;
  }

  .BackButton {
    text-transform: uppercase;
    background-color: $clean-white;
    height: 36px!important;
  }

  .SubmitButton {
    text-transform: uppercase;
    background-color: $gunmetal;
    color: $clean-white;
    height: 36px!important;
    &:disabled {
      text-transform: uppercase;
      color: rgba($text-color, 0.25);
      background-color: $lighty-white;
      height: 36px!important;
    }
  }

  .DeleteButton {
    text-transform: uppercase;
    background-color: $tomato;
    color: $clean-white;
    height: 36px!important;;
  }
}
