@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/breakpoints';

.BreadcrumbContainer {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    overflow: hidden;

    .BreadcrumbSeparator {
        color: $clean-white;
        opacity: .34;
        padding: 0 10px;
    }
    
    .BreadcrumbItemContainer, .BreadcrumbAdditionalItemContainer {
        box-sizing: border-box;
        &:after {
            display:block;
            content: '';
            border-bottom: 2px solid $primary;
            transform: scaleX(0);
            transition: transform 150ms ease-in-out;
        }
        &:hover:after {
            transform: scale(1);
        }
    }

    .BreadcrumbSeparator, .BreadcrumbItemContainer {
        @media #{$sm-low} {
            display: none;
        }
    }
}
