@import '../../../theme/partials/constants/colors.scss';

.ListItemContainer {
    position: relative;
    border-style: solid;
    border-width: 1px;
    border-color: $gunmetal;
    background-color: $clean-white;
    border-radius: 5px;
    height: 60px;
    display: flex;
    cursor: pointer;

    &.ListItemActive {
        border-color: $primary;
    }

    .ListItemExpandedPreview {
        max-height: 0;
        height: 0;
        width: 0;
        transition: all 100ms ease-in-out;
    }

    &.ListItemHover {
        .ListItemExpandedPreview {
            z-index: 10;
            height: 250px;
            max-height: 250px;
            width: 100%;
            margin: auto;
            position: absolute;
            top: 0; 
            left: 0; 
            bottom: 0; 
            right: 0;
            transition: all 100ms ease-in-out;
        }
    }

    .ListItemPreview {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $gunmetal;
    }

    .ListItemDescription {
        width: 70%;
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .ListItemActiveIcon {
            position: absolute;
            right: 5px;
            top: 5px;
            font-size: 15px;
            color: $primary;
        }
    }
}