@import '../../theme/partials/constants/colors.scss';
@import '../../theme/mixins/modal-full.scss';
.CloudinaryPublicIdImageModalPreview {
  @include full-screen-modal();
  :global(.ant-modal-content) {
    :global(.ant-modal-header) {
      height: 15%;
    }
    :global(.ant-modal-body) {
      height: 85%;
      padding: 24px 20px;
    }
  }
  .CloudinaryImageModalContainer {
    height: 100%;

    .CloudinaryImageModalColumns {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .CloudinaryImageModalColumns + .CloudinaryImageModalColumns {
      height: 100%;
    }

    .CloudinaryImageModalInputContainer {
      p {
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 5px;
      }
      .CloudinaryImageErrorLabel {
        font-weight: normal;
        font-size: 14px;
        padding-top: 7px;
        color: $tomato;
      }
    }

    .CloudinaryImageInputError {
      border-color: $tomato;
    }

    .CloudinaryImageModalInputContainer + .CloudinaryImageModalInputContainer {
      padding-top: 20px;
    }
  }
}
