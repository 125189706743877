.RepositoryListContainer {
    overflow-y: auto;
    height: 100%;
    margin-left: 0;
    margin-right: 0;

    .RepositoryListEmptyIcon {
        font-size: 70px;
    }

    .RepositoryListEmptyDescription {
        font-size: 1.5em;
    }

}
