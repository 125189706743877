.FilterByContentDefinitionListWrapper {
  height: 100%;

  :global(.ant-list-header) {
    height: 13%;
  }

  :global(.ant-spin-nested-loading) {
    height: 87%;
    overflow-y: auto;
  }

  > a {
    display: block;
    padding-top: 10px;
  }

  .FilterByContentDefinitionCheckboxWrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
