@import '../../../../theme/partials/constants/colors.scss';
@import '../../../../theme/partials/constants/variables';

.statsContainer {
  margin: 3px 0px 21px;
}

.topDefContainer {
  display: flex;
  flex-direction: row;
  color: $dark; 
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;

  .definitionData {
    flex-grow: 1;
    font-size: $fontSizeSmall;
  }

  .activeContentCount {
    margin-left: 10px;
    font-size: $fontSizeLarge;
    width: 50px;
    text-align: right;
    text-overflow: ellipsis;
  }
}