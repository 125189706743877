@import '../../theme/partials/constants/colors';
@import '../../theme/mixins/modal-full.scss';

.monacoEditorStyle > div:first-child {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  > :nth-child(-n+2) {
    margin-top: 5px;
  }
}

:global(.DraftEditor-editorContainer) {
  font-size: 13px;
  
  h1 {
    font-size: 48px;
  }
  
  h2 {
    font-size: 32px;
  }
  
  h3 {
    font-size: 24px;
  }
  
  h4 {
    font-size: 18px;
  }
}

.LongTextEditorMDE {
  :global(.CodeMirror){
    &:not(:global(.CodeMirror-fullscreen)) {
       height: 600px;
     }
    }
  :global(.editor-preview-side) {
    ol, ul {
      padding-left: 40px;
    }
  }

  :global(.editor-toolbar.fullscreen), :global(.CodeMirror-fullscreen), :global(.editor-preview-side) {
    z-index: 1000;
  }

  :global(.editor-toolbar) {
    > button {
      line-height: 1;
    }
  }

  &.LongTextEditorMDEDisabled {
    pointer-events: none;
    background-color: #f5f5f5;
    :global(.CodeMirror) {
      background-color: #f5f5f5;
    }
  }
}

.CloudinaryImageModalPreview {
  @include full-screen-modal();
  :global(.ant-modal-content) {
    :global(.ant-modal-header) {
        height: 15%;
    }
    :global(.ant-modal-body) {
        height: 85%;
        padding: 24px 20px;
    }
  }
}
