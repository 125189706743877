@import '../../../theme/partials/constants/breakpoints';

.sceneTitleContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  @media #{$md-low} {
    flex-direction: column
  }

  .sceneTitleWrapper {
    margin-top: 10px;
    @media #{$lg-up} {
    margin-left: 20px;
    flex: 2;
    margin-top: 3px;
  }
  }
}