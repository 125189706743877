// FONT
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:regular,bold,italic&subset=latin,latin-ext');

// Custom icons
@import "../assets/custom_icons/css/icons.css";

@import './partials/constants/colors.scss';
@import './partials/constants/variables.scss';

// Components override
@import './partials/alert.scss';
@import './partials/checkbox.scss';
@import './partials/date-picker.scss';
@import './partials/input.scss';
@import './partials/input-number.scss';
@import './partials/layout.scss';
@import './partials/menu.scss';
@import './partials/modal.scss';
@import './partials/popconfirm.scss';
@import './partials/radio.scss';
@import './partials/table.scss';
@import './partials/tag.scss';
@import './partials/time-picker.scss';
@import './partials/typography.scss';
@import './partials/notifications.scss';

// Animations
@import './animations/animations.scss';

:root {
	--cf-card-box-shadow: 0px 5px 12px -5px rgba(0, 0, 0, 0.23);
	--cf-card-border-radius: 8px;
	--cf-card-background-color: #fff;
	--cf-card-padding: 1rem;
	--cf-card-margin: 0 0 1rem;

	--cf-scene-background: #f7f7f8;

	--cf-settings-content-header-border: 1px solid rgba(0, 0, 0, .08);
}

fieldset {
	border: 0;
}

body {
	font-family: "Noto sans", sans-serif;
	background-color: $clean-white;
}

html, body, #root {
	width: 100%;
}

#root {
	height: 100%;
}

// Needed because antd won't import this rule from itself 😐
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td,hr,button,article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
	margin: 0;
	padding: 0;
}

// AWS Amplify Base container height fix
[class*="Section__container"] {
	height: 100%;
	min-height: 100vh;
}
