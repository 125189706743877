@import "../../theme/partials/constants/variables";
@import "../../theme/partials/constants/breakpoints";

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  min-width: 64px;
  .BlackTextLogo {
    content: url('../../assets/images/logo.svg');
    @media #{$sm-low} {
      content:url(../../assets/images/logo_collapsed.svg);
    }

    &.ForceCollapsed {
      content:url(../../assets/images/logo_collapsed.svg);
    }
  }
  .WhiteTextLogo {
    content:url(../../assets/images/logo_white.svg);
    @media #{$sm-low} {
      content:url(../../assets/images/logo_collapsed.svg);
    }

    &.ForceCollapsed {
      content:url(../../assets/images/logo_collapsed.svg);
    }
  }
}
