@import "../../theme/partials/constants/colors";
@import "../../theme/partials/constants/variables";
@import "../../theme/animations/animations";

.NotificationHub {
    z-index: 11;
    position: absolute;
    background-color: transparent;
    padding: 1.5rem;
    max-height: calc((100vh - #{$top-nav-height} - 40px));
    overflow-y: auto;
    overflow-x: hidden;
}

.EmptyNotification {
    border-radius: 20px;
    background-color: white;
    box-shadow: var(--cf-card-box-shadow);
    padding: 1.5rem;
    animation-name: fadeInRight;
    animation-duration: 0.2s;
    animation-fill-mode: both;
}