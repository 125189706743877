@import '../../theme/partials/constants/colors.scss';
@import '../../theme/partials/constants/breakpoints.scss';
@import '../../theme/partials/constants/variables.scss';

%onboarding-button {
  display: block;
  font-weight: 400;
  width: 100%;
}

@mixin social-button($background, $color) {
  @extend %onboarding-button;
  background-color: $background;
  color: $color;

  &:hover {
    background-color: $background;
    color: $color;
  }
}

@mixin create-social-button($name, $background, $color) {
  .#{$name} {
    @include social-button($background, $color);
  }
}

.Onboarding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.OnboardingTopContainer {
  margin-bottom: 2rem;
}
.OnboardingBottomContainer {
  display: flex;
  align-items: center;
  @media #{$sm-low} {
    justify-content: center;
  }
}

.OnboardingContentLeft,
.OnboardingContentRight {
  width: 50%;
  display: flex;
  margin: 0 50px;

  @media #{$sm-low} {
    width: auto;
    padding: 0 30px;
  }
}

.OnboardingContentLeft {
  justify-content: flex-end;

  @media #{$sm-low} {
    display: none;
  }
}

.OnboardingContentRight {
  justify-content: flex-start;
  @media #{$sm-low} {
    margin: 0;
    justify-content: initial;
    align-items: center;
  }
}

.OnboardingLogo {
  height: auto;
  width: 100%;
}

.OnboardingForm {
  max-width: 28rem;
  padding: var(--cf-card-padding);
  width: 100%;
}

.OnboardingSideContent {
  max-width: 450px;
}

.OnboardingButton {
  @extend %onboarding-button;
}

.OnboardingPrivacy {
  color: rgba(#000, 0.87);
  font-size: .75rem;
  font-style: italic;
  line-height: 1.66;
  letter-spacing: 0.03333rem;
}

.OnboardingPrivacy a {
  font-weight: 700;
  margin: 0 .25rem;
}

.OnboardingOr :global(.ant-divider-inner-text) > span {
  color: rgba(#000, 0.54);
}

.OnboardingOrSignup,
.OnboardingSubTitle,
.OnboardingTitle {
  text-align: center;
}

.OnboardingSubTitle,
.OnboardingTitle {
  display: block;
  width: 100%;
}

.OnboardingSubTitle {
  font-size: 1rem;
  font-style: italic;
  margin-top: 1rem;
  opacity: 0.63;
}

.OnboardingTitle {
  color: rgba(#000, 0.87);
  font-size: 1.75rem;
}

@include create-social-button(SignInWithGoogle, #4285f4, rgba(255, 255, 255, .7));

[data-test="authenticator-error"][class*="Toast__toast"] {
  background-color: #d42f4d;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.SignUpPasswordHintContainer {
  max-width: 80%;
  .SignUpPasswordHint {
    .WarningIcon {
      color: $warning;
      margin-right: 10px;
    }
  }
}
