@import '../../../../theme/partials/constants/colors.scss';
@import '../../../../theme/partials/constants/variables';

.statistic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 220px;
  align-items: center;
  font-size: 24px;

    .suffix {
      font-size: 14px;
    }

    .title {
      color : rgba(0, 0, 0, 0.55); 
      font-size: 18px; 
    }
}
