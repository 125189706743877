@import "../../../theme/partials/constants/colors";
@import "../../../theme/partials/constants/variables";
@import "../../../theme/partials/constants/breakpoints";

.EmailNotVerifiedButton {
    border-color: $warning;
    color: $warning;
    text-transform: capitalize;

    &:disabled, &:focus, &:hover {
        border-color: $warning;    
        color: $warning;
    }
}