@import '../../theme/partials/constants/colors';

.IconDefault {
  color: $pale-grey;
  font-size: 20px;

  &.IconSelected {
    color: $primary;
  }
}
