@import "../../../theme/partials/constants/variables";
@import "../../../theme/partials/constants/breakpoints";

.sceneDashboardHeader {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-attachment: fixed;

  .headerTitle {
    color: #fff;
    padding-top: 35px;
    padding-left: 30px;
  }

  .headerLinks {
    display: flex;
    padding-left: 30px;
    padding-bottom: 35px;
    padding-top: 16px;

    .headerLinksElement {
      list-style-type: none;
      font-size: 16px;
      padding-right: 40px;
      > a {
        color: #fff;
      }
      .activeLink {
        text-decoration: underline;
      }
    }
  }

  @media #{$xs-only} {
    height: 15%;
    justify-content: center;
    .headerTitle {
      text-align: center;
      padding: 10px 0;
    }

    .headerLinks {
      display: block;
      padding: 0;

      .headerLinksElement{
        padding: 0 0 10px 0;
        margin: 0;
        text-align: center;
      }
    }
  }
}