@import '../../theme/partials/constants/colors';
@import '../../theme/partials/constants/variables';

.UpdatePublishingChannelFormItemContainer {
  width: 100%;
  padding: 15px 0;
}

.SubmitButton {
    text-transform: uppercase;
    background-color: $gunmetal;
    color:  $clean-white;
    height: 36px!important;
    &:hover, &:focus {
        color: $clean-white;
        background-color: $gunmetal;
    }
    &:disabled {
      text-transform: uppercase;
      color: rgba($text-color, 0.25);
      background-color: $lighty-white;
      height: 36px!important;
    }
}

.BackButton {
  text-transform: uppercase;
  background-color: $clean-white;
  height: 36px!important;
}