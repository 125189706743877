@import '../partials/constants/_colors.scss';
@import '../partials/constants/_variables.scss';
@import '../partials/constants/breakpoints';

@mixin select-tag-backgroundColor() {
    :global(.ant-select-selection--multiple) {
        :global(.ant-select-selection__rendered) {
            :global(.ant-select-selection__choice) {
                border-radius: $defaultBorderRadius;
                background-color: $primary;
                color: $clean-white;
                :global(.ant-select-selection__choice__remove) {
                    color: $clean-white;
                }
            }
        }
    }
}

@mixin select-tag-maxWidth() {
    :global(.ant-select-selection--multiple) {
        :global(.ant-select-selection__rendered) {
            :global(.ant-select-selection__choice) {
                max-width: 110px;

                @media #{$lg_only} {
                    max-width: 80px;
                }
                @media #{$md_only} {
                    max-width: 60px;
                }
            }
        }
    }
}
