@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';

.SpaceSwitcherWrapper {
    background: $dark;
    width: 250px;
    height: 100%;
    font-size: 16px;
    margin-right: 50px;

    @media #{$md-only} {
        width: 200px;
    }

    @media #{$sm-only} {
        width: 150px;
    }

    @media #{$xs-only} {
        width: 125px;
    }

    .SpaceSwitcherContainer {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 8px;
        position: relative;

        .SpaceSwitcherSpaceIcon {
            font-size: 30px;
            color: $clean-white;

            @media #{$sm-low} {
                display: none;
            }
        }

        .SpaceSwitcherTextContainer {
            flex-grow: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $clean-white;
            height: 100%;
            padding-left: 10px;
            line-height: initial;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            .SpaceSwitcherSpaceName {
                font-weight: 700;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .SpaceSwitcherCTA {
                color: $primary
            }
        }
    }
}

.SpaceSwitcherModalWrapper {
    :global(.ant-modal) {
        :global(.ant-modal-content) {
            background-color: white;

            :global(.ant-modal-header) {
                opacity: .61;
                background-color: white;
            }
        }
    }
}
