@import '../../theme/partials/constants/colors';

.LinkedContentWrapper {
  padding: 10px;
}

.LinkedContentModalWrapper {
  :global(.ant-modal) {
    height: 90%;
    width: 70%!important;

    :global(.ant-modal-content) {
      height: 100%;
      width: 100%;
      border-radius: 5px;

      :global(.ant-modal-body) {
        height: 100%;
        width: 100%;
        padding: 35px;
      }
    }
  }

  .LinkedContentModalBodyWrapper {
    height: 100%;
    width: 100%;

    .LinkedContentModalFiltersWrapper {
      height: 10%;
    }

    .LinkedContentModalContentsWrapper {
      overflow-y: auto;
      height: 80%;
      width: 100%;
      padding: 10px;

      .LinkedContentModalNotContentsFoundWrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .LinkedContentModalPaginationWrapper {
      height: 10%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}
