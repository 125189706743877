@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/breakpoints';
@import '../../theme/partials/constants/colors';

.ContentItemIconStyleOverwrite {
  vertical-align: middle!important;
  line-height: 0!important;
  padding-right: 5px;
  font-size: $fontSizeExtraLarge;
}

.ContentIconTagsIconStyleOverwrite {
  vertical-align: middle!important;
  line-height: 0!important;
  padding-right: 5px;
  font-size: $fontSizeSmall;
}

.ContentItemTextWithEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ContentItemListItemWrapper {  
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
  :global(.ant-list-item-content.ant-list-item-content-single) {
    width: 100%;
  }

  .AddOpacity {
    opacity: 0.5;
  }

  .ShiftedItemHelper {
    position: absolute;
    height: 100%;
    width: 100px;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    button {
      animation: bounce 1s infinite;
    }
    z-index: 10;
  }

  @keyframes bounce {
    0% {left: 5px;}
    25%, 75% {left: 15px;}
    50% {left: 20px;}
    100% {left: 0px;}
  }
  
  .ContentItemListItemContainer {
    display: flex;
    width: 100%;
    height: 230px;
    transition: transform 0.5s ease;
    cursor: pointer;
    &.TranslateContentItem {
      transform: translate(-70%);
      @media #{$lg-low} {
        transform: translate(-100%);
      }
    }

    @media #{$md-up} {
      height: 160px;
    }

    .ContentItemBodyContainer {
      padding: 5px 0 5px 5px;

      .ContentItemContentNameContainer {
        display: flex;
        align-items: center;
        p {
          font-weight: bold;
          margin-bottom: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: $fontSizeDefault;
        }
      }

      .ContentItemLeftBodyContainer {
        display: flex;
        flex-direction: column;
        @media #{$md-up} {
          height: 80%;
        }

        .ContentItemContentPublicIdContainer {
          display: flex;
          align-items: center;
          padding: 5px 0 0;
        }
        .ContentItemContentLocaleContainer {
          display: flex;
          align-items: center;
          padding: 5px 0 0;
        }

        .ContentItemContentTagsContainer {
          height: auto;
          max-height: 65px;
          padding-top: 5px;
          text-align: left;
          overflow: hidden;
        }
      }

      .ContentItemRightBodyContainer {
        @media #{$md-up} {
          height: 80%;
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .ContentItemDefinitionMnenonicContainer, .ContentItemRepositoryNameContainer {
          display: flex;
          align-items: center;
        }

        .ContentItemContentPublicationDateContainer {
          margin-bottom: 5px;
          &:nth-child(1) :global(.anticon-calendar) {
            color: $light-moss-green;
          }

          &:nth-child(2) :global(.anticon-calendar) {
            color: $tomato;
          }

          .ContentItemContentDateContainer {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .ContentItemActionsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.StatusSection {
  display: flex;
  justify-content: flex-end;
}

.ContentItemStatusSection {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  width: 70%;
  padding-left: 10px;
  @media #{$lg-low} {
    width: 100%;
  }
}