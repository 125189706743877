@import '../../theme/partials/constants/breakpoints';

.RepositorySummaryContainer {
    height: 90px;
    background-color: #fff;
    @media #{$sm-low} {
        height: auto;
        align-items: center;
        .RepositorySummaryTeamMembers {
          flex-wrap: wrap;
          justify-content: center;
        }
    }
    .RepositorySummaryTeamMembers {
        display: flex;
        align-items: center;
        // padding: 10px;
    }

    .RepositorySummaryStats {
        display: flex;
        padding-right: 33px;
        justify-content: flex-end;
        .RepositorySummaryNumber {
            font-size: 24px;
        }
    }
}