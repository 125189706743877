@import '../../../../theme/partials/constants/colors.scss';
@import '../../../../theme/partials/constants/variables';

.welcome {
  color: $gunmetal;
  font-size: 26px;
  flex-grow: 1;
}

.sdkContainer {
  margin-left: 10px;
}
