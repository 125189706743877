$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);

.ant-tag {
	border-radius: 4px;
	position: relative;
	margin-bottom: 5px;
	height: auto;

	.team-member {
		background: #ffffff;
		color: #fff;
		max-width: 170px;
		height: 54px;
		margin-right: 0;
		.anticon-cross {
			cursor: pointer;
			font-weight: bold;
			position: absolute;
			left: 5px;
			color: black;
			transition: all 0.3s $ease-out;
			opacity: 0.66;

			&:hover {
				opacity: 1;
			}
		}
		.round-avatar {
			color: #fff;
		}
	}

	.team-member .center {
		display: flex;
		align-items: center;
	}

	.anticon-cross {
		// .iconfont-size-under-12px(10px);
		cursor: pointer;
		font-weight: bold;
		margin-left: 3px;
		transition: all 0.3s $ease-out;
		opacity: 0.66;

		&:hover {
			opacity: 1;
		}
	}
}
