@import '../../theme/partials/constants/variables';
@import '../../theme/partials/constants/colors';

.NavActionsContainer {
  height: 100%;
  display: flex;
  color: $light-gunmetal;
  align-items: center;
  margin-left: auto;
  min-width: 220px;

  :global(.anticon) {
    &:hover {
      color: $clean-white;
    }
  }

  .NavActionsSettingsContainer {
    display: flex;
    color: $light-gunmetal;
    cursor: pointer;
    align-items: center;
    padding-right: 20px;

    &:hover {
      color: $clean-white;
    }

    i {
      font-size: 24px;
      padding-right: 2px;
    }
  }


  .NavActionsLogoutContainer {
    margin-left: auto;
    font-size: $fontSizeNavIcon;
    padding-right: 20px;
    cursor: pointer;
  }
}
