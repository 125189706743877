.TeamMembersContainer {
    display: flex;
    .TeamMembersHeader {
        font-size: 14px;
        padding-top: 5px;
        display: inline-block;
    }

    .TeamMembersBody {
        display: flex;
        & > div {
            margin-left: 10px;
        }
    }
}