$table-padding-vertical: 15px;
$table-padding-horizontal: 10px;
$borderGray: #e5e5e5;
$table-header-bg: #fff;
$table-color: #fff;
$table-row-hover-bg: #ffffff;

.ant-table-wrapper {
  width: 100%;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
  }
}

.ant-table {
  table {
    background: $table-color;
  }
  &-thead > tr > th {
    border-bottom: 2px solid $borderGray;
    text-align: center;
    * {
      font-size: 12px;
      text-transform: capitalize;
      font-weight: bold;
      text-size-adjust: 100%;
      .ant-table-column-sorter {
        display: inline-block;
        .ant-table-column-sorter-up {
          display: block;
        }
        .ant-table-column-sorter-down {
          display: block;
        }
      }
    }
  }
  &-thead > tr,
  &-tbody > tr {
    transition: all .3s;
    border-bottom: 1px solid $borderGray;
  }
  &-tbody > tr {
    height: 90px;
    > td {
      text-align: center;
    }
  }
}
