@import '../../../theme/partials/constants/colors.scss';
@import '../../../theme/partials/constants/variables';

.ArrayFieldPopoverOverride {
  &:global(.ant-popover) {
    max-width: 75%;
  }
}

.ArrayFieldErrorClass {
  border: 1px solid $tomato;
}

.AddArrayFieldButton {
  line-height: 1;
  vertical-align: middle;
}


.ArrayFieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  margin-right: 10px;

    .ArrayItemWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;

      .ArrayItemButtonsWrapper {
        display: flex;
        flex-direction: row;          
        margin-bottom: 5px;    
        justify-content: flex-end;
        width: 100%;
        
        .ArrayItemIndex {
          line-height: normal;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          margin-bottom: 5px;
        }

        button {
          margin-left: 5px;
        }
      }
    }

}
